export const tintColorLight = '#9500b7' // #2f95dc';
export const tintColorDark = '#c900cf'

export type themeColors = {
  text: string
  textLightMuted: string
  textMuted: string
  background: string
  backgroundSep: string
  backgroundSepLight: string
  tint: string
  secondary: string
  abort: string
  tabIconDefault: string
  tabIconSelected: string
  green: string
  red: string
  disabled: string
}

export type ColorNames = keyof themeColors

export type ThemeColors = {
  [key in ColorNames]: string
}

const colors = {
  light: {
    text: '#000',
    textLightMuted: '#272727',
    textMuted: '#727272',
    background: '#f2f2f2',
    backgroundSep: '#e0e1e2',
    backgroundSepLight: '#e6e5eb',
    tint: tintColorLight,
    secondary: 'rgb(16, 115, 210)', // secondary: 'rgb(41, 151, 255)',
    abort: '#cc2222',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    green: 'green',
    red: '#ff0000',
    disabled: '#777777',
  },
  'no-preference': {
    text: '#000',
    textLightMuted: '#272727',
    textMuted: '#727272',
    background: '#f2f2f2',
    backgroundSep: '#e0e1e2',
    backgroundSepLight: '#e6e5eb',
    tint: tintColorLight,
    secondary: 'rgb(21, 127, 230)',
    abort: '#cc2222',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    green: 'green',
    red: '#ff0000',
    disabled: '#777777',
  },
  dark: {
    text: '#fff',
    textLightMuted: '#d4d4d6',
    textMuted: '#979797',
    background: '#010101',
    backgroundSep: '#212025',
    backgroundSepLight: '#1c1824',
    tint: tintColorDark,
    secondary: 'rgb(18, 114, 207)', // secondary: 'rgb(0, 102, 204)',
    abort: '#cc2222',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    green: '#11ff11',
    red: '#ff1111',
    disabled: '#777777',
  },
} as { light: ThemeColors; dark: ThemeColors; 'no-preference': ThemeColors }

export default colors
