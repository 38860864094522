import React from 'react'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { settingsChange } from '../../redux/userSlice'
import { updateState as updateAppState } from '../../redux/appSlice'
import { AccountStackParamList } from './index'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { View, Pressable, StyleSheet } from 'react-native'
//TODO: Replace react-native-appearance by { useColorScheme } from 'react-native' define type in new App.types.d
// import useColorScheme from '../../hooks/useColorScheme'
import { Text } from '../../components/Themed'
import { FontAwesome } from '@expo/vector-icons'
import SettingsItem from './settingsItem'
import BackendRequest from '../../components/backendRequest'

function Settings({
  navigation,
}: NativeStackScreenProps<AccountStackParamList, 'userSettings'>): JSX.Element {
  const dispatch = useAppDispatch()
  const { user, appState, colorScheme } = useAppSelector(({ app, user }) => ({
    user,
    appState: app,
    colorScheme: app.colorScheme,
  }))

  const handleSettingsChange = ({
    type,
    name,
    value,
  }: {
    type: string
    name: string
    value: string | boolean
  }) => {
    if (type === 'user') {
      dispatch(settingsChange({ name, value }))
      BackendRequest('userChangeSetting', {
        field: name,
        value: typeof value === 'boolean' ? (value ? 1 : 0) : value,
      })
        .then((data: { success: boolean; message?: string }) => {
          if (!data.success) {
            console.log('apiError: ', data.message)
            return false
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => console.log(error))
    }
    if (type === 'app') {
      const newState = Object.assign({}, appState)
      newState[name] = Number(value)
      dispatch(updateAppState(newState))

      BackendRequest('updateAppSettings', {
        field: name,
        value: typeof value === 'boolean' ? (value ? 1 : 0) : value,
      })
        .then((data: { success: boolean; message?: string }) => {
          if (!data.success) {
            console.log('apiError: ', data.message)
            return false
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => console.log(error))
    }
  }

  return (
    <View
      style={{
        ...styles.container,
      }}
    >
      <SettingsItem
        type="user"
        name="klickerVibration"
        info="Funktioniert nicht im Stromsparmodus"
        title="Vibration beim Klickern"
        inputType="boolean"
        value={user.klickerVibration}
        onChange={handleSettingsChange}
      />
      {user.id === 1 && (
        <>
          <Pressable
            onPress={() => navigation.navigate('dfAppSettings' as never)}
            style={{
              flexDirection: 'row',
              paddingLeft: 15,
              paddingVertical: 15,
              borderTopWidth: 0.5,
              borderTopColor: colorScheme === 'dark' ? '#444' : '#aaa',
              borderBottomWidth: 0.5,
              borderBottomColor: colorScheme === 'dark' ? '#444' : '#aaa',
              marginBottom: 10,
            }}
          >
            <View style={{ flex: 5 }}>
              <Text>Df-App-Einstellungen</Text>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'center',
                paddingRight: 10,
              }}
            >
              <Text>
                <FontAwesome
                  size={15}
                  name="chevron-right"
                  style={{ color: '#777' }}
                />
              </Text>
            </View>
          </Pressable>
          <Pressable
            onPress={() => navigation.navigate('settingsTest' as never)}
            style={{
              flexDirection: 'row',
              paddingLeft: 15,
              paddingVertical: 15,
              borderTopWidth: 0.5,
              borderTopColor: colorScheme === 'dark' ? '#444' : '#aaa',
              borderBottomWidth: 0.5,
              borderBottomColor: colorScheme === 'dark' ? '#444' : '#aaa',
              marginBottom: 10,
            }}
          >
            <View style={{ flex: 5 }}>
              <Text>Test</Text>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'center',
                paddingRight: 10,
              }}
            >
              <Text>
                <FontAwesome
                  size={15}
                  name="chevron-right"
                  style={{ color: '#777' }}
                />
              </Text>
            </View>
          </Pressable>
        </>
      )}

      {/* user.role === 'admin' && (
        <>
          <SettingsItem
            style={styles.settingsItem}
            type="app"
            name="terminalMin"
            title="Terminal Umfragen Min."
            inputType="number"
            value={
              appState && appState.terminalMin
                ? appState.terminalMin.toString()
                : 0
            }
            onChange={handleSettingsChange}
          />
          <SettingsItem
            style={styles.settingsItem}
            type="app"
            name="terminalGood"
            title="Terminal Umfragen super"
            inputType="number"
            value={
              appState && appState.terminalGood
                ? appState.terminalGood.toString()
                : 0
            }
            onChange={handleSettingsChange}
          />
        </>
          ) */}
      {/* Use a light status bar on iOS to account for the black space above the modal */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
    paddingBottom: 10,
    paddingTop: 11,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})

export default Settings
