import React, { useLayoutEffect } from 'react'
import { Provider } from 'react-redux'
import { Platform } from 'react-native'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import * as Notifications from 'expo-notifications'
// import * as SecureStore from 'expo-secure-store'
import * as SecureLocalStorage from './components/secureLocalStorage'
import AppView from './screens/AppWrapper'

if (Platform.OS !== 'web') {
  Notifications.setNotificationHandler({
    handleNotification: async () => {
      return {
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: false,
      }
    },
  })
}

export default function App(): JSX.Element {
  useLayoutEffect(() => {
    // SplashScreen.preventAutoHideAsync()
    // SecureStore.setItemAsync('api_password', 'eU3iWx76he62L24bJgkf')
    SecureLocalStorage.setItemAsync('api_password', 'eU3iWx76he62L24bJgkf')
  }, [])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppView />
      </PersistGate>
    </Provider>
  )
}
