import React from 'react'
import { Modal, View, TouchableHighlight } from 'react-native'
import { Text } from './Themed'
import Colors from '../constants/Colors'
import updateText from '../constants/updateText'
import { useAppSelector } from '../redux/hooks'

interface Props {
  visible: boolean
  onClose: () => void
  infoText?: string
}
const updateInfoModal = ({
  visible,
  onClose,
  infoText,
}: Props): JSX.Element => {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 22,
        }}
      >
        <View
          style={{
            margin: 20,
            backgroundColor: Colors[colorScheme].background,
            borderRadius: 20,
            paddingVertical: 25,
            paddingHorizontal: 30,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
            borderColor: '#777777',
            borderWidth: 1,
          }}
        >
          {infoText && <Text>{infoText}</Text>}
          <Text
            style={{
              textAlign: 'center',
              fontSize: 19,
              fontWeight: 'bold',
              marginBottom: 18,
            }}
          >
            Neu in dieser Version
          </Text>
          <Text
            style={{
              marginBottom: 33,
              textAlign: 'center',
            }}
          >
            {updateText}
          </Text>

          <TouchableHighlight
            style={{
              borderRadius: 20,
              padding: 13,
              elevation: 2,
              backgroundColor: Colors[colorScheme].tint,
            }}
            onPress={() => {
              onClose()
            }}
          >
            <Text
              style={{
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Alles klar
            </Text>
          </TouchableHighlight>
        </View>
      </View>
    </Modal>
  )
}

export default updateInfoModal
