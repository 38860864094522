import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import React, { useState } from 'react'
import { View, StyleSheet, Switch, Pressable, Keyboard } from 'react-native'
import { Text, Button, TextInput } from '../../components/Themed'
import RandomString from '../../components/randomString'
import BackendRequest from '../../components/backendRequest'
import { AccountStackScreenProps } from '.'
import { useAppSelector } from '../../redux/hooks'
import User from '../../types/user'

type Props = {
  route: { params: { user: User; method: string } }
} & AccountStackScreenProps<'userEdit'>
// Admin-Interface um App-Nutzer zu bearbeiten oder anzulegen
export default function UserEdit({ route, navigation }: Props): JSX.Element {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const { user, method } = route.params

  const [firstName, setFirstName] = useState(
    method === 'edit' ? user.firstName : ''
  )
  const [lastName, setLastName] = useState(
    method === 'edit' ? user.lastName : ''
  )
  const [admin, setAdmin] = useState(
    method === 'edit' ? user.role === 'admin' : false
  )
  const [deactivated, setDeactivated] = useState(
    method === 'edit' ? user.deactivated === '1' : false
  )
  const [accessCode, setAccessCode] = useState(
    method === 'edit' ? user.accessCode : RandomString(6)
  )

  const handleSave = () => {
    const action = method === 'edit' ? 'update' : 'add'
    //TODO: Backend Team speichern
    BackendRequest(action + 'User', {
      firstName,
      lastName,
      role: admin ? 'admin' : 'durchfuehrer',
      deactivated: deactivated ? 1 : 0,
      id: method === 'edit' ? user.id : 0,
      accessCode,
      team: 4,
    })
      .then((data: { success: boolean; message?: string }) => {
        //TODO: add klicks unfinished to session
        if (!data.success) {
          console.log('apiError: ', data.message)
          return false
        } else {
          navigation.goBack()
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => console.log(error))
  }

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={styles.container}
      extraScrollHeight={40}
      keyboardOpeningTime={0}
    >
      <Pressable onPress={Keyboard.dismiss}>
        <View style={{ marginBottom: 20 }}>
          <Text>Vorname</Text>
          <TextInput
            value={firstName}
            onChangeText={setFirstName}
            placeholder="Vorname"
          />
        </View>
        <View style={{ marginBottom: 30 }}>
          <Text>Nachname</Text>
          <TextInput
            value={lastName}
            onChangeText={setLastName}
            placeholder="Nachname"
          />
        </View>
        <View style={{ marginBottom: 40, flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 20 }}>Administrator</Text>
          </View>
          <View style={{ flex: 1, alignItems: 'flex-end', paddingRight: 15 }}>
            <Switch
              style={{ flex: 1 }}
              trackColor={{
                false: colorScheme === 'dark' ? '#777' : '#555',
                true: 'green',
              }}
              thumbColor="white"
              onValueChange={() => setAdmin(!admin)}
              value={admin}
            />
          </View>
        </View>
        <View style={{ marginBottom: 40, flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 20 }}>Deaktiviert</Text>
          </View>
          <View style={{ flex: 1, alignItems: 'flex-end', paddingRight: 15 }}>
            <Switch
              style={{ flex: 1 }}
              trackColor={{
                false: colorScheme === 'dark' ? '#777' : '#555',
                true: 'green',
              }}
              thumbColor="white"
              onValueChange={() => setDeactivated(!deactivated)}
              value={deactivated}
            />
          </View>
        </View>
        <View style={{ marginBottom: 30 }}>
          <Text>Zugangscode</Text>
          <TextInput
            value={accessCode}
            onChangeText={setAccessCode}
            placeholder="Zugangscode"
          />
        </View>
        <View style={{ marginTop: 25 }}>
          <Button
            onPress={handleSave}
            title={method === 'edit' ? 'Speichern' : 'Hinzufügen'}
          />
        </View>
      </Pressable>
      {/* Use a light status bar on iOS to account for the black space above the modal */}
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',

    justifyContent: 'center',
    paddingVertical: 18,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
    paddingVertical: 8,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
