// IMPORTANT: If file is changed, consider changing Backend functions in other frontend apps as well!

import React, { useState } from 'react'
import { useAppDispatch } from '../redux/hooks'
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View,
  Image,
  ActivityIndicator,
} from 'react-native'
import { Text, TextInput, Button } from '../components/Themed'
import { PasswordForgottenRequest } from '../components/backendRequest'
import { RootStackScreenProps } from '../types'

// Login-View bei initialer Anmeldung zur App
export default function PasswordForgotten({
  navigation,
}: RootStackScreenProps<'PasswordForgotten'>) {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const handleSubmit = () => {
    setIsLoading(true)
    try {
      PasswordForgottenRequest(email).then(
        (response: { success: boolean; message?: string }) => {
          if (response.success) {
            setSubmitSuccess(true)
          } else {
            setSubmitErrorMessage(
              response.message &&
                typeof response.message === 'string' &&
                response.message.length > 0
                ? response.message
                : 'Es ist ein Fehler passiert. Peinlich...'
            )
              ? response.message
              : 'Es ist ein Fehler aufgetreten.'
            setSubmitError(true)
          }
          console.log(response)
          setIsLoading(false)
        }
      )
    } catch (error) {
      console.log('login: Password Forgotten Request could not be sent', error)
    }
  }

  // const { signIn } = React.useContext(AuthContext)
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
    >
      <View style={{ alignItems: 'center', minWidth: 240 }}>
        <Text style={styles.title}>Passwort zurücksetzen</Text>
      </View>
      {submitSuccess ? (
        <View style={{ minWidth: 300 }}>
          <Text style={{ marginBottom: 15, textAlign: 'center' }}>
            Anweisungen zum Zurücksetzen des Passworts wurden Dir per Mail
            gesandt.
          </Text>
          <Text style={{ marginBottom: 50, textAlign: 'center' }}>
            Falls die Mail im Spam gelandet ist, füge bitte den Absender zu den
            sicheren Absendern hinzu.
          </Text>
          <View style={{ alignItems: 'center' }}>
            <Button title=" Zum Login " onPress={() => navigation.goBack()} />
          </View>
        </View>
      ) : submitError ? (
        <View>
          <Text style={{ marginBottom: 40 }}>Fehler: {submitErrorMessage}</Text>
          <Button title="Zurück" onPress={() => navigation.goBack()} />
        </View>
      ) : isLoading ? (
        <View
          style={{
            marginTop: 60,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator />
        </View>
      ) : (
        <View>
          {errorMessage.length > 0 ? (
            <Text style={{ color: 'red' }}>{errorMessage}</Text>
          ) : null}
          <View style={{ marginTop: 20, paddingVertical: 40, minWidth: 240 }}>
            <Text style={{ marginBottom: 8 }}>Deine E-Mail:</Text>
            <TextInput
              value={email}
              onChangeText={setEmail}
              onSubmitEditing={handleSubmit}
              placeholder="E-Mail"
              autoCorrect={false}
              autoFocus={true}
              autoCapitalize="none"
            />
          </View>
          <View style={{ alignItems: 'center' }}>
            <Button title="Weiter" onPress={handleSubmit}>
              Weiter
            </Button>
          </View>
        </View>
      )}

      {/* Use a light status bar on iOS to account for the black space above the modal */}
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 240,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 40,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
