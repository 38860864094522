import React, { useEffect } from 'react'
import { Pressable, Animated, LayoutAnimation } from 'react-native'
import { ReportStatus } from '../machelSlice'
import { Text } from '../../../components/Themed'
import { FontAwesome } from '@expo/vector-icons'

export default function StatusIcon({
  status,
  active,
  onClick,
}: {
  status: ReportStatus
  active: boolean
  onClick: (statusId: number) => void
}) {
  const handleClick = () => {
    if (onClick) onClick(status.id)
  }

  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  })
  return (
    <Pressable onPress={handleClick}>
      <Animated.View
        style={{
          borderRadius: 999,
          justifyContent: 'center',
          alignItems: 'center',
          width: 52,
          height: 52,
          borderWidth: 1.5,
          borderColor: status.color,
          backgroundColor: active ? status.color : 'transparent',
          opacity: active ? 1 : 0.65,
        }}
      >
        <Text>
          <FontAwesome
            size={21}
            name={status.symbol}
            style={{
              color: active ? 'rgb(255, 255, 255)' : status.color,
            }}
          />
        </Text>
      </Animated.View>
    </Pressable>
  )
}
