import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  isLoading: boolean
  isSignout: boolean
  userToken: string | null
  firstName: string
  lastName: string
  role: string
  admin: boolean
  active: boolean
  deactivated: boolean
  id: number | null
  expoPushToken: string | null
  apiPassword: string | null
  apiKey: string | null
  onDuty: boolean
  klickerVibration: boolean
}

export type LoginData = {
  token: string | null
  firstName: string | null
  lastName: string | null
  role: string | null
  admin: boolean
  id: number | null
  active: boolean
  deactivated: boolean
  klickerVibration: boolean
  onDuty: boolean
}

type SettingsData = {
  name: string
  value: string | number | boolean
}

const initialState: UserState = {
  isLoading: true,
  isSignout: false,
  userToken: null,
  firstName: '',
  lastName: '',
  role: '',
  admin: false,
  id: null,
  active: false,
  deactivated: false,
  klickerVibration: false,
  onDuty: false,
  expoPushToken: null,
  apiKey: null,
  apiPassword: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    restoreToken: (state, action: PayloadAction<LoginData>) => {
      const {
        token,
        firstName,
        lastName,
        id,
        role,
        active,
        onDuty,
        deactivated,
        klickerVibration,
      } = action.payload
      state.userToken = token
      state.firstName = firstName ? firstName : ''
      state.lastName = lastName ? lastName : ''
      state.role = role ? role : ''
      state.id = id
      state.active = active
      state.deactivated = deactivated
      state.onDuty = onDuty
      state.klickerVibration = klickerVibration
      state.isLoading = false
    },
    signIn: (state, action: PayloadAction<LoginData>) => {
      const {
        token,
        firstName,
        lastName,
        id,
        role,
        admin,
        active,
        onDuty,
        deactivated,
        klickerVibration,
      } = action.payload
      state.userToken = token
      state.firstName = firstName ? firstName : ''
      state.lastName = lastName ? lastName : ''
      state.role = role ? role : ''
      state.admin = admin
      state.id = id
      state.active = active ? true : false
      state.deactivated = deactivated
      state.onDuty = onDuty
      state.klickerVibration = klickerVibration
      state.isSignout = false
    },
    setApiKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload
    },
    setApiPassword: (state, action: PayloadAction<string>) => {
      state.apiPassword = action.payload
    },
    signOut: (state) => {
      state.userToken = null
      state.apiKey = null
      state.isSignout = true
      state.onDuty = false
    },
    settingsChange: (state, action: PayloadAction<SettingsData>) => {
      //TODO: Mapped Type für Props hinzufügen, basierend auf Elementen von initial state: https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
      state[action.payload.name] = action.payload.value
    },
    setOnDuty: (state, action: PayloadAction<boolean>) => {
      state.onDuty = action.payload
    },
    setExpoPushToken: (state, action: PayloadAction<string>) => {
      state.expoPushToken = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  restoreToken,
  signIn,
  setApiKey,
  setApiPassword,
  signOut,
  settingsChange,
  setOnDuty,
  setExpoPushToken,
} = userSlice.actions

export default userSlice.reducer
