import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { Text, TextInput } from '../../../components/Themed'
import {
  View,
  StyleSheet,
  Pressable,
  ScrollView,
  Animated,
  KeyboardAvoidingView,
} from 'react-native'
import LoadingOverlay from '../../../components/loadingOverlay'
import { Alert } from '../../../components/alert'
import * as ImagePicker from 'expo-image-picker'
import AddAssets from '../addMedia'
import { FontAwesome } from '@expo/vector-icons'
import Colors from '../../../constants/Colors'
import * as SecureStore from '../../../components/secureLocalStorage'
import BackendRequest, { UploadFile } from '../../../components/backendRequest'
import {
  setAddingNewReport,
  setNewReportUser,
  setNewReportSubject,
  setNewReportText,
  setNewReportLocation,
  addNewReportAsset,
  setReportsUpToDate,
  reset,
} from '../machelSlice'
import { MachelStackScreenProps } from '..'

const AddReport = ({
  navigation,
  route,
}: MachelStackScreenProps<'AddReport'>) => {
  const dispatch = useAppDispatch()
  const { app, user, machel } = useAppSelector(({ app, user, machel }) => ({
    app,
    user,
    machel,
  }))

  const [focus, setFocus] = useState('subject')
  const [isTransmittingReport, setIsTransmittingReport] = useState(false)

  const iSubjectRef = useRef()
  const iTextRef = useRef()

  const showError = (message: string) => {
    Alert.alert('Fehler', message)
  }

  const subjectFadeAnim = useRef(new Animated.Value(0)).current // initial value 0
  const textFadeAnim = useRef(new Animated.Value(0)).current // initial value 0
  const locationFadeAnim = useRef(new Animated.Value(0)).current // initial value 0
  const assetsFadeAnim = useRef(new Animated.Value(0)).current // initial value 0

  const showTextInput = () => {
    Animated.spring(textFadeAnim, {
      toValue: 1,
      useNativeDriver: true,
    }).start()

    Animated.spring(locationFadeAnim, {
      toValue: 1,
      useNativeDriver: true,
      delay: 200,
    }).start()

    Animated.spring(assetsFadeAnim, {
      toValue: 1,
      useNativeDriver: true,
      delay: 400,
    }).start()
  }

  const handleAssetAdded = (image: ImagePicker.ImageInfo) => {
    dispatch(addNewReportAsset(image))
  }

  const submitReport = async () => {
    console.log('add:', machel.newReport.assets)
    if (!machel.newReport.subject || machel.newReport.subject.length < 3) {
      showError('Betreff darf nicht leer sein.')
      console.log('machel: add: Betreff darf nicht leer sein.')
      return
    }
    setIsTransmittingReport(true)
    const userId = await SecureStore.getItemAsync('user_id')
    const apiKey = await SecureStore.getItemAsync('api_key')
    if (!userId || !apiKey) {
      return null
    }

    const uploadFiles: UploadFile[] = []
    for (const asset of machel.newReport.assets) {
      uploadFiles.push({
        uri: asset.uri,
        width: asset.width ? asset.width : undefined,
        height: asset.height ? asset.height : undefined,
        duration: asset.duration ? asset.duration : undefined,
      })
    }

    BackendRequest(
      'machel/reports/upload',
      {
        subject: machel.newReport.subject,
        text: machel.newReport.text ? machel.newReport.text : '',
        location: machel.newReport.location ? machel.newReport.location : '',
      },
      'multipart/form-data',
      uploadFiles
    )
      .then((res) => {
        if (res?.success) {
          dispatch(setReportsUpToDate(false))
          dispatch(reset({ startNewReport: false }))
          setIsTransmittingReport(false)
          dispatch(setAddingNewReport(false))
          navigation.navigate('ReportList', { reload: true })
        } else {
          console.log('machel: add: report upload error', res.message)
          showError(
            res.message
              ? res.message
              : 'Meldung konnte nicht hochgeladen werden.'
          )
        }
      })
      .catch((error) =>
        console.log('machel: add: report upload failed: ' + error.message)
      )
  }

  useLayoutEffect(() => {
    if (!machel.newReport) dispatch(setAddingNewReport(true))
  }, [])

  useEffect(() => {
    if (route.params && route.params.reset) {
      dispatch(setAddingNewReport(true))
    }
  }, [route.params])

  useEffect(() => {
    if (!user.id) {
      Alert.alert(
        'Fehler beim Anlegen der Meldung: keine User-ID. Bitte Info an Thomas Cerny.'
      )
    } else {
      dispatch(
        setNewReportUser({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        })
      )
    }
    /*
    ;(async () => {
      const { status } = await Camera.getCameraPermissionsAsync()
      if (status === 'granted') dispatch(setCameraAccess(true))
      else {
        const r = await Camera.requestCameraPermissionsAsync()
        dispatch(setCameraAccess(r.status === 'granted'))
      }
    })()
    */

    Animated.spring(subjectFadeAnim, {
      toValue: 1,
      delay: 500,
      useNativeDriver: true,
    }).start()
    setTimeout(showTextInput, 700)
  }, [])
  /* <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        extraScrollHeight={30}
        keyboardOpeningTime={0}
      >
      */
  return (
    <KeyboardAvoidingView
      behavior={'padding'}
      enabled
      keyboardVerticalOffset={0}
      style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}
    >
      <ScrollView>
        <View>
          <Animated.View
            key="subject"
            style={{
              paddingHorizontal: 4,
              opacity: subjectFadeAnim,
              transform: [
                {
                  translateY: subjectFadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [20, 0], // 0 : 150, 0.5 : 75, 1 : 0
                  }),
                },
              ],
            }}
          >
            <Text style={styles.fieldTitle}>Betreff</Text>
            <TextInput
              ref={iSubjectRef}
              value={
                machel.newReport && machel.newReport.subject
                  ? machel.newReport.subject
                  : ''
              }
              onChangeText={(val) => dispatch(setNewReportSubject(val))}
              onSubmitEditing={() => {
                if (iTextRef.current && iTextRef.current.focus)
                  iTextRef.current.focus()
                setFocus('text')
                showTextInput()
              }}
              autoCorrect={true}
              autoFocus={focus === 'subject' && !machel.newReport.subject}
            />
          </Animated.View>
          <Animated.View
            key="text"
            style={{
              paddingHorizontal: 4,
              opacity: textFadeAnim,
              transform: [
                {
                  translateY: textFadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [20, 0], // 0 : 150, 0.5 : 75, 1 : 0
                  }),
                },
              ],
            }}
          >
            <Text style={styles.fieldTitle}>Beschreibung</Text>
            <TextInput
              multiline
              numberOfLines={5}
              ref={iTextRef}
              value={
                machel.newReport && machel.newReport.text
                  ? machel.newReport.text
                  : ''
              }
              onChangeText={(val) => dispatch(setNewReportText(val))}
              onSubmitEditing={() => setFocus('location')}
              style={{ minHeight: 100 }}
              autoCorrect={true}
              autoFocus={focus === 'text'}
            />
          </Animated.View>
          <Animated.View
            key="location"
            style={{
              paddingHorizontal: 4,
              opacity: locationFadeAnim,
              transform: [
                {
                  translateY: locationFadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [20, 0], // 0 : 150, 0.5 : 75, 1 : 0
                  }),
                },
              ],
            }}
          >
            <Text style={styles.fieldTitle}>Ort (optional)</Text>
            <TextInput
              value={
                machel.newReport && machel.newReport.location
                  ? machel.newReport.location
                  : ''
              }
              onChangeText={(val) => dispatch(setNewReportLocation(val))}
              onSubmitEditing={() => setFocus('')}
              // autoCorrect={true}
              autoFocus={focus === 'location'}
            />
          </Animated.View>
          <Animated.View
            key="assets"
            style={{
              flexDirection: 'column',
              alignItems: 'stretch',
              paddingHorizontal: 0,
              opacity: assetsFadeAnim,
              transform: [
                {
                  translateY: assetsFadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [20, 0], // 0 : 150, 0.5 : 75, 1 : 0
                  }),
                },
              ],
            }}
          >
            <View>
              <Text style={{ ...styles.fieldTitle, paddingLeft: 9 }}>
                Fotos (optional)
              </Text>

              {/* }<Text style={styles.title}>Medien</Text> */}
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                <AddAssets
                  assets={machel.newReport.assets.filter((el) =>
                    el ? true : false
                  )}
                  onAssetAdded={handleAssetAdded}
                />
                {/*
                <AssetView
                  assets={machel.newReport.assets
                    .filter((el) => (el ? true : false))
                    .map((el, index) => ({
                      id: index,
                      uri: el.uri,
                      index,
                      filename: el.uri.split('/').pop(),
                      type: el.type ? el.type : 'image',
                    }))}
                  showAddButton={true}
                  onAdd={takePhotoAsync}
                  onItemClick={handleAssetThumbClick}
                  style={{}}
                  /> */}
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 40,
                paddingBottom: 30,
              }}
            >
              <Pressable
                onPress={submitReport}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.8 : 1,
                })}
              >
                <View
                  style={{
                    backgroundColor: Colors[app.colorScheme].tint,
                    padding: 15,
                    paddingHorizontal: 24,
                    borderRadius: 40,
                  }}
                >
                  <Text style={{ fontSize: 18, color: '#fff' }}>
                    Absenden &nbsp;{' '}
                    <FontAwesome
                      size={18}
                      color="#ffffff"
                      name={'send-o'}
                      style={{
                        marginRight: 5,
                        marginTop: -6,
                      }}
                    />
                  </Text>
                </View>
              </Pressable>
            </View>
          </Animated.View>
        </View>
      </ScrollView>
      {isTransmittingReport && (
        <LoadingOverlay text="Meldung wird gesendet"></LoadingOverlay>
      )}
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  fieldTitle: {
    textAlign: 'left',
    paddingHorizontal: 5,
    fontSize: 17,
    marginTop: 25,
    marginBottom: 2,
  },
  transmittingOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default AddReport
