import React from 'react'
import { SectionList, StyleSheet, View, SectionListData } from 'react-native'
import { SettingsItem } from './settingsListItem'
import { SettingsListStackScreenProps } from './index'
import { SettingsListItem } from './settingsListItem'
import { SettingsListSectionHeader } from './settingsListSectionHeader'

export type SettingsSection = {
  title: string
  icon: string
  data?: SettingsItem[]
}
type Props = { data: SettingsSection[] } & SettingsListStackScreenProps<'List'>

const SettingsList = ({ navigation, route, data }: Props): JSX.Element => {
  const listData: SettingsSection[] = data
    ? data
    : route.params.data
    ? route.params.data
    : []
  return (
    <SectionList
      sections={listData}
      style={{ flex: 1, width: '100%', marginTop: 14 }}
      // showsVerticalScrollIndicator={false}
      // bounces={false}

      onEndReachedThreshold={0.5}
      ItemSeparatorComponent={ItemSeparator}
      keyExtractor={(item) => item.name}
      renderItem={(props) => {
        const isFirstElement = props.index === 0
        const isLastElement = props.index === props.section.data.length - 1

        return (
          <SettingsListItem
            navigation={navigation}
            route={route}
            item={props.item}
            isFirstElement={isFirstElement}
            isLastElement={isLastElement}
          />
        )
      }}
      renderSectionHeader={({ section: { title, icon } }) => (
        <SettingsListSectionHeader icon={icon} title={title} />
      )}
    />
  )
}

const styles = StyleSheet.create({
  iconWrapper: {
    padding: 10,
    borderRadius: 8,
  },
})

const ItemSeparator = () => (
  <View
    style={{
      height: 1,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    }}
  ></View>
)

export default SettingsList
