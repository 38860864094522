// IMPORTANT: If file is changed, consider changing Backend functions in other frontend apps as well!

import React, { useState } from 'react'
import { useAppDispatch } from '../redux/hooks'
import { signIn, setExpoPushToken } from '../redux/userSlice'
import * as Notifications from 'expo-notifications'
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View,
  Image,
} from 'react-native'
import { Text, TextInput, Button } from '../components/Themed'
import logo from '../assets/images/logo.png'
import * as SecureStore from '../components/secureLocalStorage'
import BackendRequest, {
  ApiReturnValue,
  BackendRequestData,
  CheckLoginCode,
  Login,
} from '../components/backendRequest'
import ValidateEmail from '../components/validateEmail'
import { RootStackScreenProps } from '../types'
import User from '../types/user'

// Login-View bei initialer Anmeldung zur App
export default function LoginScreen({
  navigation,
}: RootStackScreenProps<'Login'>) {
  const [loginCode, setLoginCode] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordErrorText, setPasswordErrorText] = useState('')

  const [loginErrorText, setLoginErrorText] = useState('')
  const [showResetPasswort, setShowResetPasswort] = useState(false)
  const dispatch = useAppDispatch()
  const [errorMessage, setErrorMessage] = useState('')

  const registerForPushNotificationsAsync = async () => {
    console.log(Platform.OS)
    if (Platform.OS !== 'web') {
      // if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        alert('login: Failed to get push token for push notification!')
        return
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data
      dispatch(setExpoPushToken(token))

      BackendRequest('userChangeSetting', {
        field: 'expoPushToken',
        value: token,
      })
        .then((data) => {
          if (data.success) {
            return
          }
        })
        .catch((error: string) => console.log(error))
    } else {
      console.log('login: Must use physical device for Push Notifications')
    }
    // }
  }

  const checkCode = async () => {
    // console.log('check code')
    CheckLoginCode(loginCode).then((data) => {
      // console.log('checklogincode: ', data)
      if (!data.success || !data.user) {
        console.log('apiError: ', data.message)
        setErrorMessage('Das hat nicht geklappt.')
        setLoginCode('')

        return false
      } else {
        if (data.user.deactivated === false) {
          registerForPushNotificationsAsync()
          SecureStore.setItemAsync('user_token', loginCode)
          SecureStore.setItemAsync('user_id', data.user.id.toString())
          SecureStore.setItemAsync('api_key', data.user.apiKey)
          //TODO: get API password after successful login and save in SecureStore - currently in App.tsx
          dispatch(
            signIn({
              token: loginCode,
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              role: data.user.role,
              admin: data.user.admin,
              id: Number(data.user.id),
              active: data.user.active,
              deactivated: false,
              klickerVibration: data.user.klickerVibration,
              onDuty: data.user.onDuty,
            })
          )
        } else {
          setErrorMessage('login: Konto wurde deaktiviert.')
        }
      }
    })
  }

  const login = () => {
    Login(email, password).then((data: BackendDataLogin) => {
      // console.log('checklogincode: ', data)

      if (!data.success || !data.user) {
        if (data.remainingLoginAttempts === undefined) {
          console.log('apiError: ', data.message)
          setErrorMessage('Das hat nicht geklappt.')
        } else {
          if (data.remainingLoginAttempts < 1) {
            setShowResetPasswort(true)
            return
          }
          setLoginErrorText(
            `Das war falsch. Verbleibende Versuche: ${data.remainingLoginAttempts}`
          )
        }

        return false
      } else {
        if (data.user.deactivated === false) {
          registerForPushNotificationsAsync()
          SecureStore.setItemAsync('user_token', loginCode)
          SecureStore.setItemAsync('user_id', data.user.id.toString())
          SecureStore.setItemAsync('api_key', data.user.apiKey)
          //TODO: get API password after successful login and save in SecureStore - currently in App.tsx
          console.log('loginSuccess')
          dispatch(
            signIn({
              token: loginCode,
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              role: data.user.role,
              admin: data.user.admin,
              id: Number(data.user.id),
              active: data.user.active,
              deactivated: false,
              klickerVibration: data.user.klickerVibration,
              onDuty: data.user.onDuty,
            })
          )
        } else {
          setErrorMessage('login: Konto wurde deaktiviert.')
        }
      }
    })
  }

  const handleSubmitOld = () => {
    checkCode()
  }

  const handleSubmit = () => {
    const emailValid = ValidateEmail(email)
    if (!emailValid) {
      setEmailError(true)
      return
    }
    setEmailError(false)

    if (password.length < 6) {
      setPasswordError(true)
      setPasswordErrorText('Das ist noch etwas zu kurz.')
      return
    }
    setPasswordError(false)
    login()
  }

  const handlePasswordForgotten = () => {
    navigation.navigate('PasswordForgotten')
  }

  const resetPassword = () => {
    navigation.navigate('PasswordForgotten')
  }

  // const { signIn } = React.useContext(AuthContext)
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
    >
      <View style={{ alignItems: 'center' }}>
        <Image
          source={logo}
          style={{ width: 180, height: 180, marginBottom: 20 }}
        />
      </View>

      {errorMessage.length > 0 ? (
        <Text style={{ color: 'red' }}>{errorMessage}</Text>
      ) : null}
      {/* <View style={{ marginTop: 20, paddingVertical: 17 }}>

        <Text style={{ marginBottom: 8 }}>Zugangscode eingeben:</Text>
        <TextInput
          value={loginCode}
          onChangeText={setLoginCode}
          onSubmitEditing={handleSubmit}
          placeholder="Zugangscode"
          autoCorrect={false}
          autoCapitalize="none"
        />
      </View> */}
      {showResetPasswort ? (
        <View style={{ marginTop: 20, paddingVertical: 12, minWidth: 240 }}>
          <Text style={{ marginBottom: 25 }}>
            Das hat nicht so ganz geklappt und Du wurdest deshalb leider
            gesperrt.
          </Text>
          <Text style={{ marginBottom: 45 }}>
            Um Dich wieder einloggen zu können, musst Du leider Dein Passwort
            zurücksetzen:
          </Text>
          <View style={{ alignItems: 'center' }}>
            <Button title=" Passwort zurücksetzen " onPress={resetPassword} />
          </View>
        </View>
      ) : (
        <View>
          <View style={{ marginTop: 20, paddingVertical: 12, minWidth: 240 }}>
            <Text style={{ marginBottom: 7 }}>E-Mail:</Text>
            <TextInput
              value={email}
              onChangeText={setEmail}
              placeholder="E-Mail"
              autoCorrect={false}
              autoFocus={true}
              autoCapitalize="none"
            />
            <Text
              style={{
                marginTop: 5,
                color: '#ff0000',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 13,
              }}
            >
              {emailError
                ? 'Das ist noch nicht wirklich ne Email-Adresse.'
                : ' '}
            </Text>
          </View>
          <View style={{ marginTop: 10, paddingTop: 12, minWidth: 240 }}>
            <Text style={{ marginBottom: 7 }}>Passwort:</Text>
            <TextInput
              value={password}
              onChangeText={setPassword}
              onSubmitEditing={handleSubmit}
              placeholder="Passwort"
              autoCorrect={false}
              autoCapitalize="none"
              secureTextEntry={true}
            />
            <Text
              style={{
                marginTop: 5,
                color: '#ff0000',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 13,
              }}
            >
              {passwordError
                ? passwordErrorText
                  ? passwordErrorText
                  : 'Das stimmt so nicht ganz...'
                : ' '}
            </Text>
          </View>
          <View style={{ marginTop: 0, alignItems: 'center' }}>
            <Text
              style={{ fontWeight: 'bold', color: '#ff0000', marginBottom: 20 }}
            >
              {loginErrorText.length > 0 ? loginErrorText : ' '}
            </Text>
            <Button title="Weiter" onPress={handleSubmit} />

            <View style={{ marginTop: 40, alignItems: 'center' }}>
              <Button
                title=" Passwort vergessen "
                onPress={handlePasswordForgotten}
              />
            </View>
          </View>
        </View>
      )}

      {/* Use a light status bar on iOS to account for the black space above the modal */}
    </KeyboardAvoidingView>
  )
}

interface BackendDataLogin extends ApiReturnValue {
  remainingLoginAttempts: number
  user?: User
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
