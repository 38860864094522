import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DurchlaufTime } from '../constants/durchlauf'
export interface DfState {
  current: number
  waiting: number
  waitingTime: number
  waitingNr: number
  capacity: number
  soll: number
  sollCurrent: number
  durchlaufList: DurchlaufTime[]
  durchlaufListUpdated: number
  durchlaufCurrent: DurchlaufTime | null
  durchlaufNext: DurchlaufTime | null
  updated: number | null
  opening: string
  closing: string
  terminalInterviewsPerHourMin: number
  terminalInterviewsPerHourGood: number
}

type UpdateData = {
  current: number
  waiting: number
  capacity: number
  updated: number
}

export interface WaitingTimeAndClosing {
  waitingTime: number
  opening: string
  closing: string
}

export interface DurchlaufListUpdate {
  durchlaufList: DurchlaufTime[]
  durchlaufListUpdated: number
}

export interface DurchlaufCurrentUpdate {
  durchlaufCurrent: DurchlaufTime | null
  durchlaufNext: DurchlaufTime | null
}

const initialState: DfState = {
  current: 0,
  waiting: 0,
  waitingTime: 0,
  waitingNr: 0,
  capacity: 0,
  soll: 0,
  sollCurrent: 0,
  durchlaufList: [],
  durchlaufListUpdated: 0,
  durchlaufCurrent: null,
  durchlaufNext: null,
  opening: '',
  closing: '',
  terminalInterviewsPerHourMin: 15,
  terminalInterviewsPerHourGood: 29,
  updated: null,
}

export const dfSlice = createSlice({
  name: 'df',
  initialState,
  reducers: {
    updateDf: (state, action: PayloadAction<UpdateData>) => {
      state.current = action.payload.current >= 0 ? action.payload.current : 0
      state.waiting = action.payload.waiting
      state.capacity = action.payload.capacity
      state.updated = action.payload.updated
      state.soll = action.payload.current + action.payload.capacity
    },
    updateWaitingTimeAndOpening: (
      state,
      action: PayloadAction<WaitingTimeAndClosing>
    ) => {
      state.waitingTime = action.payload.waitingTime
      state.opening = action.payload.opening
      state.closing = action.payload.closing
    },
    setSollCurrent: (state, action: PayloadAction<number>) => {
      state.sollCurrent = action.payload
    },
    setDurchlaufList: (state, action: PayloadAction<DurchlaufListUpdate>) => {
      state.durchlaufList = action.payload.durchlaufList
      state.durchlaufListUpdated = Date.now()
    },
    updateCurrentDurchlauf: (
      state,
      action: PayloadAction<DurchlaufCurrentUpdate>
    ) => {
      state.durchlaufCurrent = action.payload.durchlaufCurrent
      state.durchlaufNext = action.payload.durchlaufNext
    },
    setWaitingNr: (state, action: PayloadAction<number>) => {
      state.waitingNr = action.payload
    },
    setTerminalParams: (
      state,
      action: PayloadAction<{ terminalMin: number; terminalGood: number }>
    ) => {
      state.terminalInterviewsPerHourMin = action.payload.terminalMin
      state.terminalInterviewsPerHourGood = action.payload.terminalGood
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateDf,
  updateWaitingTimeAndOpening,
  setSollCurrent,
  setDurchlaufList,
  updateCurrentDurchlauf,
  setWaitingNr,
  setTerminalParams,
} = dfSlice.actions

export default dfSlice.reducer
