import backendUrl from '../constants/api.js'
// import * as SecureStore from 'expo-secure-store'
import * as SecureStore from './secureLocalStorage'
import { Platform } from 'react-native'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const base64 = require('base-64')
import axios from 'axios'
import RandomString from './randomString'

//TODO: Change to RxJS: https://rxjs.dev/api/ajax/ajax
// Anfragen an den Server per AJAX

export type BackendRequestData = Record<
  string,
  string | number | boolean | Record<string, string | number | boolean>
>
export type UploadFile = {
  uri: string
  type?: string
  width?: number
  height?: number
  duration?: number
  base64?: string
}

export interface ApiReturnValue {
  success: boolean
  message: string
  [key: string]: unknown
}
export async function BackendRequestAsync(
  action: string,
  data: BackendRequestData = {},
  contentType = 'application/json',
  files: UploadFile[] = []
) {
  // let returnValue: ApiReturnValue = { success: true, message: '' }
  let returnValue = { success: true, message: '' }
  let userId = 0
  try {
    userId = Number(await SecureStore.getItemAsync('user_id'))
  } catch (e) {
    console.log('backendRequest: userId is no number')
    returnValue.success = false
    returnValue.message = 'Auth failed'
    return returnValue
  }

  let apiKey = await SecureStore.getItemAsync('api_key')
  // const apiPassword = await SecureStore.getItemAsync('api_password')
  // if (!apiPassword) return null
  const auth = await getApiAuth()

  if (!auth) {
    returnValue.success = false
    returnValue.message = 'Auth failed'
    return returnValue
  }

  if (apiKey === null) apiKey = await getApiKey()
  if (typeof apiKey !== 'string') {
    returnValue.success = false
    returnValue.message = 'No API Key found'
    return returnValue
  }
  const headers = new Headers({
    Authorization: auth,
    Accept: 'application/json',
    'Content-Type': contentType,
  })

  let url = backendUrl
  if (action.indexOf('/') === -1) url += 'secure/index.php?action=' + action
  else {
    url += action
    if (action.substring(action.length, action.length + 1) !== '/') url += '/'
  }

  let requestData: BodyInit

  if (contentType === 'application/json') {
    requestData = JSON.stringify(
      data ? { ...data, apiKey, uid: userId } : { apiKey, uid: userId }
    )
  } else if (contentType === 'multipart/form-data') {
    const formData = new FormData()
    formData.append('uid', userId ? userId.toString() : '')
    formData.append('apiKey', apiKey)
    formData.append('data', JSON.stringify({ ...data, apiKey, uid: userId }))

    let assetCount = 0
    for (const asset of files) {
      const localUri = asset.uri
      let filename = ''
      let fileType = ''
      // fix as react-native-web provides base64 string instead of filename as uri
      if (Platform.OS === 'web') {
        fileType = asset.uri.substring(5, asset.uri.indexOf(';'))
        filename = `${RandomString(30)}.${fileType.substring(
          fileType.indexOf('/') + 1,
          fileType.length
        )}`
      } else {
        filename = localUri.split('/').pop()
        if (!filename) {
          continue
        }
        const match = /\.(\w+)$/.exec(filename)
        fileType = match
          ? `${asset.type ? asset.type : 'image'}/${match[1]}`
          : `${asset.type ? asset.type : 'image'}`
      }
      // fix as react-native-web provides base64 string instead of filename as uri
      if (Platform.OS === 'web' && asset.uri.length > 300) {
        const r = await fetch(asset.uri)
        const t = await r.blob()
        formData.append(`asset${assetCount}_file`, t)
      } else {
        formData.append(`asset${assetCount}_file`, {
          // esline-disable-next-line
          name: filename,
          type: fileType,
          uri:
            Platform.OS === 'ios'
              ? asset.uri.replace('file://', '')
              : asset.uri,
        })
      }
      formData.append(`asset${assetCount}_mediaType`, fileType.toString())
      formData.append(`asset${assetCount}_filename`, filename.toString())
      if (asset.width)
        formData.append(`asset${assetCount}_width`, asset.width.toString())
      if (asset.height)
        formData.append(`asset${assetCount}_height`, asset.height.toString())

      formData.append(
        `asset${assetCount}_duration`,
        asset.duration ? (asset.duration / 1000).toFixed(0) : '0'
      )
      assetCount++
    }

    formData.append('assetCount', assetCount.toString())
    requestData = formData
  } else {
    console.log('api: invalid content type')
    returnValue.success = false
    returnValue.message = 'Invalid content type'
    return returnValue
  }

  try {
    let data: ApiReturnValue
    if (Platform.OS === 'web') {
      const res = await axios(url, {
        // mode: 'no-cors',
        method: 'post',

        headers: {
          Authorization: auth,
          Accept: 'application/json',
          'content-type': contentType,
        },

        data: requestData,
      })
      data = res.data
    } else {
      const res = await fetch(url, {
        // mode: 'no-cors',
        method: 'POST',
        headers,
        body:
          contentType === 'application/json'
            ? JSON.stringify(requestData)
            : requestData,
      })
      data = await res.json()
      // console.log('backendAction', action)
      // console.log(action, await res.text())

      // if (action == 'machel/reports/statusupdate') {
      //   console.log(requestData)
      //   console.log(await res.text())
      // }
    }

    if (!data) {
      console.log('apiError: No message')
      returnValue.success = false
      returnValue.message = 'apiError: Empty response'
    }
    returnValue = data
    if (data.success) {
      returnValue = data
    } else {
      console.log('apiError: ', data.message ? data.message : 'No message')
      returnValue.success = false
      returnValue.message = data.message
      if (data.debug) {
        console.log('apiDebug', data.debug)
      }
    }
  } catch (error) {
    console.log('apiError: ', data.message ? data.message : 'No message')
    returnValue.success = false
    returnValue.message = 'API Error'
  }

  return returnValue
}

export async function BackendRequestSchnack(
  action: string,
  data: Record<string, unknown> = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  // const apiPassword = await SecureStore.getItemAsync('api_password')
  // if (!apiPassword) return null
  const userId = await SecureStore.getItemAsync('user_id')
  let apiKey = await SecureStore.getItemAsync('api_key')
  if (!userId)
    alert(
      'Bitte einmal von der App abmelden und wieder einloggen. Falls das Problem dann immer noch auftritt, bitte Info an Thomas :)'
    )
  if (apiKey === null) apiKey = await getApiKey()
  if (apiKey === null) return null

  const res = await fetch(
    backendUrl + 'schnack/index.php?action=' + action,
    // backendUrl + 'secure/index.php?action=' + action,
    {
      // mode: 'no-cors',
      method: 'POST',
      headers: new Headers({
        // Authorization: 'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(
        data ? { ...data, apiKey, uid: userId } : { apiKey, uid: userId }
      ),
    }
  )
  // console.log(await res.text())
  const j = await res.json()
  if (!j.success) {
    console.log('apiError: ', data.message ? data.message : 'No message')
  }
  return j
}

export async function getApiAuth() {
  const apiPassword = await SecureStore.getItemAsync('api_password')
  return !apiPassword
    ? null
    : 'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword)
}

// Temporär: Wenn noch kein API-Schlüssel vorhanden, rufe einen ab. Zukünftig erfolgt dies beim ersten Einloggen in die App und kann dann hier weg
async function getApiKey() {
  const userToken = await SecureStore.getItemAsync('user_token')
  const apiPassword = await SecureStore.getItemAsync('api_password')
  // console.log('getapikey', userToken, apiPassword)

  if (userToken) {
    const res = await fetch(
      backendUrl + 'secure/index.php?action=getApiKey',
      // backendUrl + 'index2.php?action=getApiKey&loginCode=' + userToken,
      {
        // mode: 'no-cors',
        method: 'POST',
        headers: new Headers({
          Authorization:
            'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ loginCode: userToken }),
      }
    )
    const j = await res.text()
    if (j !== '0') {
      SecureStore.setItemAsync('api_key', j)
      return j
    }
    return null
  } else return null
}

export async function CheckLoginCode(userToken: string) {
  const res = await fetch(
    // backendUrl + 'secure/index.php?action=getApiKey',
    backendUrl + 'index2.php?action=checkLoginCode',
    {
      // mode: 'no-cors',
      method: 'POST',
      headers: new Headers({
        // Authorization: 'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ loginCode: userTokenr }),
    }
  )
  // const t = await res.text()
  // console.log(t)
  const j: {
    success: boolean
    message?: string
    user?: {
      firstName: string
      lastName: string
      role: string
      id: number
      active: boolean
      deactivated: boolean
      klickerVibration: boolean
      onDuty: boolean
      admin: boolean
      apiKey: string
    }
  } = await res.json()

  return j
}

export async function PasswordForgottenRequest(email: string) {
  const res = await fetch(
    // backendUrl + 'secure/index.php?action=getApiKey',
    backendUrl + 'user/passwordforgotten/',
    {
      // mode: 'no-cors',
      method: 'POST',
      headers: new Headers({
        // Authorization: 'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ email }),
    }
  )
  // const t = await res.text()
  // console.log(t)
  const j: {
    success: boolean
    message?: string
  } = await res.json()

  return j
}

export async function PasswordResetRequest(
  email: string,
  passwordResetHash: string,
  password: string
) {
  const res = await fetch(
    // backendUrl + 'secure/index.php?action=getApiKey',
    backendUrl + 'user/passwordreset/',
    {
      // mode: 'no-cors',
      method: 'POST',
      headers: new Headers({
        // Authorization: 'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ email, passwordResetHash, password }),
    }
  )
  // const t = await res.text()
  // console.log(t)
  const j: {
    success: boolean
    message?: string
  } = await res.json()

  return j
}

export async function Login(email: string, password: string) {
  const res = await fetch(
    // backendUrl + 'secure/index.php?action=getApiKey',
    backendUrl + 'user/login/',
    {
      // mode: 'no-cors',
      method: 'POST',
      headers: new Headers({
        // Authorization: 'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ email, password }),
    }
  )
  // const t = await res.text()
  // console.log(t)
  const j: {
    success: boolean
    message?: string
    remainingLoginAttempts: number
    user?: {
      firstName: string
      lastName: string
      role: string
      id: number
      active: boolean
      deactivated: boolean
      onDuty: boolean
      admin: boolean
      apiKey: string
    }
  } = await res.json()

  return j
}

export async function ImageUpload(
  action: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: any,
  body: Record<string, unknown> = {}
): Promise<string> {
  const res = await fetch(backendUrl + 'index2.php?action=' + action, {
    method: 'POST',
    headers: new Headers({
      // Authorization: 'Basic ' + base64.encode('a2dEsfj5p4aT:' + apiPassword),
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    }),
    body: createFormData(
      Platform.OS === 'ios' ? image.uri.replace('file://', '') : image.uri,
      body
    ),
  })
  const j = await res.json()
  return j.success ? j : false
}

const createFormData = (photo: string, body = {}) => {
  const data = new FormData()

  data.append('photo', {
    name: photo.fileName,
    type: photo.type,
    uri: Platform.OS === 'ios' ? photo.uri.replace('file://', '') : photo.uri,
  })

  Object.keys(body).forEach((key) => {
    data.append(key, body[key])
  })

  return data
}

export default BackendRequestAsync
