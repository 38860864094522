import * as SecureStore from 'expo-secure-store'
import AsyncStorage from '@react-native-async-storage/async-storage'

export async function setItemAsync(key: string, data: string): Promise<void> {
  const secureStoreAvailable = await SecureStore.isAvailableAsync()
  if (secureStoreAvailable) {
    return SecureStore.setItemAsync(key, data)
  } else {
    return AsyncStorage.setItem(key, data)
  }
}

export async function getItemAsync(key: string): Promise<string | null> {
  const secureStoreAvailable = await SecureStore.isAvailableAsync()
  if (secureStoreAvailable) {
    return SecureStore.getItemAsync(key)
  } else {
    return AsyncStorage.getItem(key)
  }
}

export async function deleteItemAsync(key: string): Promise<void> {
  const secureStoreAvailable = await SecureStore.isAvailableAsync()
  if (secureStoreAvailable) {
    return SecureStore.deleteItemAsync(key)
  } else {
    return AsyncStorage.removeItem(key)
  }
}
