import React, { useState } from 'react'
import { SettingsListStackScreenProps } from './index'
import { View } from 'react-native'
import { Text, Title, TextInput, Button } from '../../components/Themed'

type Props = SettingsListStackScreenProps<'ItemDetail'>

const itemDetail = ({ route, navigation }: Props): JSX.Element => {
  const { title, value, valueType, onChange } = route.params.item
  const [val, setVal] = useState(value ? value.toString() : '')
  const handleSave = () => {
    onChange(valueType === 'number' ? Number(val) : val)
    navigation.goBack()
  }
  return (
    <View>
      <Title>{title}</Title>
      <TextInput value={val} onChangeText={setVal} />
      <Button onPress={handleSave} title="Speichern" />
    </View>
  )
}

export default itemDetail
