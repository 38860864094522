// IMPORTANT: If file is changed, consider changing Backend functions in other frontend apps as well!

import React, { useState, useEffect } from 'react'
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View,
  ActivityIndicator,
} from 'react-native'
import * as Device from 'expo-device'
import { Text, TextInput, Button } from '../components/Themed'
import { PasswordResetRequest } from '../components/backendRequest'
import { RootStackScreenProps } from '../types'

// Login-View bei initialer Anmeldung zur App
export default function PasswordForgotten({
  navigation,
  route,
}: RootStackScreenProps<'ResetPassword'>) {
  const [invalidParams, setInvalidParams] = useState(false)
  const [email, setEmail] = useState('')
  const [passwordResetHash, setPasswordResetHash] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isOnMobileDevice, setIsOnMobileDevice] = useState(
    Device.osName === 'Android' ||
      Device.osName === 'iOS' ||
      Device.osName === 'iPadOS'
  )
  const handleSubmit = () => {
    if (password1 !== password2) {
      setErrorMessage('Das ist nicht ganz dasselbe Passwort.')
      return
    } else {
      let passwordValid = true

      if (password2.length < 6) {
        setErrorMessage('Dein Passwort ist etwas zu kurz.')
        passwordValid = false
      }

      if (!passwordValid) {
        if (errorMessage.length === 0)
          setErrorMessage('Das Passwort ist jetzt nicht so sicher...')
        return
      }

      setIsLoading(true)
      PasswordResetRequest(email, passwordResetHash, password2).then(
        (response) => {
          if (response.success) {
            setSubmitSuccess(true)
          } else {
            setSubmitErrorMessage(
              response && response.message && response.message.length > 0
                ? response.message
                : 'Shiiite, es ist ein Fehler aufgetreten.'
            )
            setSubmitError(true)
          }
          console.log(response)
          setIsLoading(false)
        }
      )
    }
  }

  useEffect(() => {
    console.log(route.params)
    if (Platform.OS === 'web') {
      const queryString = new URLSearchParams(window.location.search)
      const urlEmail = queryString.get('email')
      if (urlEmail) setEmail(urlEmail)
      else setInvalidParams(true)
      const urlPasswordResetHash = queryString.get('passwordResetHash')
      if (urlPasswordResetHash) setPasswordResetHash(urlPasswordResetHash)
      else setInvalidParams(true)
    } else {
      if (
        !route.params ||
        !route.params.email ||
        !route.params.passwordResetHash
      )
        setInvalidParams(true)
      else {
        setEmail(route.params.email)
        setPasswordResetHash(route.params.passwordResetHash)
      }
    }
  }, [])

  console.log(route.params)

  // const { signIn } = React.useContext(AuthContext)
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
    >
      <Text style={styles.title}>Passwort zurücksetzen</Text>
      {invalidParams ? (
        <View>
          <Text style={{ marginBottom: 15, textAlign: 'center' }}>
            Dieser Link ist ungültig.
          </Text>
          <Text style={{ marginBottom: 15, textAlign: 'center' }}>
            Falls das Problem wiederholt auftritt, bitte Thomas C Bescheid
            sagen.
          </Text>
        </View>
      ) : submitSuccess ? (
        <View>
          <Text>
            Erfolg!! Dein Passwort wurde geändert. Einigermaßen herzlichen
            Glückwunsch!
          </Text>
          {isOnMobileDevice && (
            <Text>
              <a href="mwapp://root">Zum Login</a>
            </Text>
          )}
        </View>
      ) : submitError ? (
        <View>
          <Text style={{ marginBottom: 25 }}>Fehler: {submitErrorMessage}</Text>
          <Button title="Zurück" onPress={() => navigation.goBack()} />
        </View>
      ) : isLoading ? (
        <View
          style={{
            marginTop: 60,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator />
        </View>
      ) : (
        <View>
          <View
            style={{
              marginTop: 20,
              paddingTop: 40,
              paddingBottom: 25,
              minWidth: 240,
            }}
          >
            <Text style={{ marginBottom: 8 }}>Neues Passwort:</Text>
            <TextInput
              value={password1}
              onChangeText={setPassword1}
              placeholder="Passwort"
              autoCorrect={false}
              autoFocus={true}
              secureTextEntry={true}
              autoCapitalize="none"
            />
            <Text style={{ marginTop: 25, marginBottom: 8 }}>
              Passwort wiederholen:
            </Text>
            <TextInput
              value={password2}
              onChangeText={setPassword2}
              onSubmitEditing={handleSubmit}
              placeholder="Passwort wiederholen"
              autoCorrect={false}
              autoCapitalize="none"
              secureTextEntry={true}
            />
          </View>
          <View style={{ alignItems: 'center' }}>
            <Text
              style={{
                color: 'red',
                textAlign: 'center',
                marginBottom: 35,
                fontWeight: 'bold',
              }}
            >
              {errorMessage.length > 0 ? errorMessage : ' '}
            </Text>
            <Button title="Weiter" onPress={handleSubmit}>
              Speichern
            </Button>
          </View>
        </View>
      )}

      {/* Use a light status bar on iOS to account for the black space above the modal */}
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 40,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
