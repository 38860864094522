import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import { MachelSettingsStackScreenProps } from './index'
import {
  View,
  StyleSheet,
  FlatList,
  Alert,
  Switch,
  DeviceEventEmitter,
} from 'react-native'
import LoadingOverlay from '../../../components/loadingOverlay'
import BackendRequest, {
  ApiReturnValue,
} from '../../../components/backendRequest'
import { Text } from '../../../components/Themed'
import User from '../../../types/user'

type ItemProps = {
  user: {
    isEditor: boolean
    isDefaultEditor: boolean
  } & User
  onSetEditor: (userId: number, value: boolean) => void
  onSetDefaultEditor: (userId: number, value: boolean) => void
}

// Admin-Interface, das alle App-Nutzer mit jeweiligem Status anzeigt
function UserListItem({ user, onSetEditor, onSetDefaultEditor }: ItemProps) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const [isEditor, setIsEditor] = useState(user.isEditor)
  const [isDefaultEditor, setIsDefaultEditor] = useState(user.isDefaultEditor)

  const toggleIsEditor = () => {
    onSetEditor(user.id, !isEditor)
    setIsEditor(!isEditor)
  }

  const toggleIsDefaultEditor = () => {
    onSetDefaultEditor(user.id, !isDefaultEditor)
    setIsDefaultEditor(!isDefaultEditor)
  }

  useEffect(() => {
    setIsEditor(user.isEditor)
  }, [user.isEditor])

  useEffect(() => {
    setIsDefaultEditor(user.isDefaultEditor)
  }, [user.isDefaultEditor])

  return (
    <View
      key={user.id}
      style={{
        flex: 1,
        width: '100%',
        paddingVertical: 20,
        paddingHorizontal: 11,
        marginVertical: 0,
        flexDirection: 'row',
        borderBottomWidth: 0.5,
        backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
        borderBottomColor: colorScheme === 'dark' ? '#444' : '#ccc',
      }}
    >
      <View style={{ flex: 3, justifyContent: 'center' }}>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 16,
            justifyContent: 'flex-start',
          }}
        >
          <Text style={{ fontSize: 17 }}>
            {user.firstName} {user.lastName}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text>Macher: &nbsp;</Text>
            <Switch value={user.isEditor} onValueChange={toggleIsEditor} />
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text>Standard-Macher: &nbsp;</Text>
            <Switch
              value={user.isDefaultEditor}
              onValueChange={toggleIsDefaultEditor}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default function Macher({
  navigation,
}: MachelSettingsStackScreenProps<'Macher'>): JSX.Element {
  const { machel } = useAppSelector(({ machel }) => ({
    machel,
  }))
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingText, setIsLoadingText] = useState('Lädt Nutzer')
  const [users, setUsers] = useState<User[]>([])

  const handleSetEditor = (userId: number, value: boolean) => {
    apiRequest('editor', userId, value)
  }

  const handleSetDefaultEditor = (userId: number, value: boolean) => {
    if (value === false) {
      Alert.alert(
        'Es muss immer einen Standard-Macher geben. Zum Ändern deshalb bitte einen anderen als Standard-Macher auswählen.'
      )
      return
    }

    apiRequest('defaultEditor', userId, value)
  }

  const apiRequest = (
    roleType: 'editor' | 'defaultEditor',
    userId: number,
    value: boolean
  ) => {
    setIsLoading(true)
    setIsLoadingText('Speichern')
    const data: {
      userId: number
      value: boolean
      setEditor?: boolean
      setDefaultEditor?: boolean
    } = { userId, value }
    data[roleType === 'editor' ? 'setEditor' : 'setDefaultEditor'] = value
    BackendRequest('machel/seteditor', data)
      .then((res: { success: boolean; message?: string }) => {
        if (res.success)
          DeviceEventEmitter.emit('mwapp.event.machel.settings.fetchData')
        else
          console.log(
            'machel settings editors API error' + res.message ? res.message : ''
          )
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getUsers = () => {
    BackendRequest('getUsers')
      .then((data: BackendDataUsers) => {
        if (data.success && data.users) {
          setUsers(data.users)
          setIsLoading(false)
        } else {
          Alert.alert('Fehler beim Laden der App-Nutzer: ' + data.message)
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => console.log(error))
  }

  useEffect(() => {
    getUsers()
    const stop = navigation.addListener('focus', () => getUsers())
    return stop
  }, [])
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column', //...styles.container,
      }}
    >
      <FlatList
        style={{ flex: 1, flexDirection: 'column' }}
        data={users.map((user) => ({
          ...user,
          isEditor:
            machel.editors.filter((el) => el.userId === user.id).length > 0,
          isDefaultEditor:
            machel.editors
              .filter((el) => el.userId === user.id)
              .filter((el) => el.defaultEditor === true).length > 0,
        }))}
        keyExtractor={(item, index) => 'ggg' + item.id + index}
        renderItem={({ item }) => (
          <UserListItem
            key={item.id}
            user={item}
            onSetEditor={handleSetEditor}
            onSetDefaultEditor={handleSetDefaultEditor}
          />
        )}
      />

      {isLoading ? <LoadingOverlay text={isLoadingText} /> : null}
    </View>
  )
}

interface BackendDataUsers extends ApiReturnValue {
  users?: User[]
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    alignContent: 'stretch',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
    paddingBottom: 10,
    paddingTop: 11,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
