import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Appearance } from 'react-native'
import { ColorSchemeName } from 'react-native-appearance'
//TODO: Replace react-native-appearance by { useColorScheme } from 'react-native' define type in new App.types.d

const colorScheme = Appearance.getColorScheme()
export interface AppState {
  terminalMin: number
  terminalGood: number
  isOnline: boolean
  showUpdateText: boolean
  appUpdateId: string
  colorScheme: 'light' | 'dark'
}

type AppStateUpdate = {
  terminalMin: number
  terminalGood: number
}

const initialState: AppState = {
  terminalMin: 15,
  terminalGood: 29,
  isOnline: true,
  showUpdateText: false,
  appUpdateId: '',
  colorScheme: colorScheme === 'dark' ? colorScheme : 'light',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateState: (state, action: PayloadAction<AppStateUpdate>) => {
      const { terminalMin, terminalGood } = action.payload
      state.terminalMin = terminalMin
      state.terminalGood = terminalGood
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload
    },
    setShowUpdateText: (state, action: PayloadAction<boolean>) => {
      state.showUpdateText = action.payload
    },
    setAppUpdateId: (state, action: PayloadAction<string>) => {
      state.appUpdateId = action.payload
    },
    setColorScheme: (state, action: PayloadAction<ColorSchemeName>) => {
      if (action.payload === 'dark') state.colorScheme = action.payload
      else state.colorScheme = 'light'
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateState,
  setIsOnline,
  setShowUpdateText,
  setAppUpdateId,
  setColorScheme,
} = appSlice.actions

export default appSlice.reducer
