import { BlurView } from 'expo-blur'
import { ActivityIndicator } from 'react-native'
import { Text } from './Themed'
import { View, Platform } from 'react-native'

const LoadingOverlay = ({ text }: { text?: string }) => {
  return (
    <BlurView
      intensity={90}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: Platform.OS === 'web' ? 'rgba(0, 0, 0, .6)' : '',
        }}
      >
        <ActivityIndicator size="large" />
        <Text style={{ marginTop: 40 }}>{text ? text : 'Lädt ...'}</Text>
      </View>
    </BlurView>
  )
}

export default LoadingOverlay
