import React, {
  useState,
  useEffect,
  useRef,
  LegacyRef,
  MutableRefObject,
} from 'react'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { setReportsUpToDate } from '../machelSlice'
import { View, ScrollView, KeyboardAvoidingView, Alert } from 'react-native'
import { Text } from '../../../components/Themed'
import StatusChange from './StatusChange'
import StatusUpdate from './editStatus'
import { MachelStackScreenProps } from '../index'
import BackendRequest, {
  BackendRequestData,
  UploadFile,
} from '../../../components/backendRequest'
import FormatDate from '../../../components/formatDate'
import LoadingOverlay from '../../../components/loadingOverlay'
import AddComment from './addComment'
import Layout from '../../../constants/Layout'

const ReportDetail = ({
  navigation,
  route,
}: MachelStackScreenProps<'ReportDetail'>) => {
  const dispatch = useAppDispatch()
  const { user, machel } = useAppSelector(({ user, machel }) => ({
    user,
    machel,
  }))

  const report = machel.reports.filter(
    (el) => el.id === Number(route.params.report)
  )[0]

  const editor = machel.editors.filter((el) => el.userId === report.editor)[0]

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingText, setIsLoadingText] = useState('Lädt')
  const [updateOnReturn, setUpdateOnReturn] = useState(false)
  const [scrollToTop, setScrollToTop] = useState(true)
  const [currentReport, setCurrentReport] = useState(0)
  const msgList = useRef()

  const returnBackToOverview = () => {
    navigation.navigate(
      'ReportList',
      updateOnReturn ? { reload: true } : undefined
    )
  }

  const updateStatus = ({
    newStatus,
    comment,
    newEditor,
    mailRecipient,
    newContact,
    returnToOverview,
    files,
  }: StatusUpdateData) => {
    if (!user.id) {
      console.log('machel: statusupdate: error: no userId')
      Alert.alert(
        'Fehler: Keine UserID gefunden. Bitte Thomas C über den Fehler Bescheid sagen.'
      )
      return
    }
    setUpdateOnReturn(true)
    setIsLoadingText('Speichern')
    setIsLoading(true)
    const backendData: BackendRequestData = {
      report: report.id,
      status: newStatus,
      comment: comment ? comment : '',
      assignNewEditor: newEditor ? true : false,
      mailToContact:
        newStatus === 7 &&
        (mailRecipient !== null ||
          (newContact && newContact.email && newContact.email.length > 0))
          ? true
          : false,
      addContact: !newEditor && newContact ? true : false,
      user: user.id,
    }
    if (newEditor) backendData['newEditor'] = newEditor
    if (mailRecipient) backendData['mailRecipient'] = mailRecipient
    if (newContact) backendData['newContact'] = newContact
    BackendRequest(
      'machel/reports/statusupdate',
      backendData,
      'multipart/form-data',
      files ? files : []
    )
      .then((data) => {
        if (data && data.success) {
          setIsLoading(false)
          dispatch(setReportsUpToDate(false))

          if (returnToOverview && Layout.isMobile) returnBackToOverview()
        } else {
          Alert.alert(
            'Statusänderung konnte nicht gespeichert werden. Offline? Ansonsten bitte Info an Thomas C.'
          )
          console.log(
            'machel: statusUpdate: apiError' +
              (data.message ? ': ' + data.message : '')
          )
        }
      })
      .catch((error) => {
        Alert.alert(
          'Update konnte nicht gespeichert werden. Offline? Ansonsten bitte Info an Thomas C.'
        )
        console.log('machel: statusupdate error: ', error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setIsLoadingText('Lädt')
    // If report is opened by assigned editor, update report status to "seen" and reload report list on return to overview
    if (report.editor === user.id) {
      let reportSeenByEditor = false
      const seenStatus = report.statusChanges.filter((statusChange) => {
        if (statusChange.status === 2 && statusChange.user.id === user.id)
          return false
        else {
          return report.editor === user.id
        }
      })
      if (seenStatus.length > 0) {
        reportSeenByEditor = true
      }
      if (reportSeenByEditor) {
        setIsLoadingText('Lädt')
        updateStatus({ newStatus: 2, returnToOverview: false })
      }
    }
  }, [])

  useEffect(() => {
    if (route.params.report !== currentReport) {
      if (msgList.current && msgList.current.scrollTo)
        msgList.current.scrollTo({ x: 0, y: 0, animated: false })
      setCurrentReport(route.params.report)
    }
  }, [route.params.report])
  return (
    <KeyboardAvoidingView
      behavior={'padding'}
      enabled
      keyboardVerticalOffset={100}
      style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}
    >
      <ScrollView ref={msgList} scrollToTop={scrollToTop}>
        <View style={{ justifyContent: 'flex-start' }}>
          <View
            style={{
              width: '100%',
              flexDirection: 'column',
              // borderBottomWidth: 1,
              // borderBottomColor: Colors[app.colorScheme].backgroundSep,
            }}
          >
            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  fontSize: 17,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  paddingTop: 25,
                  paddingBottom: 28,
                  paddingHorizontal: 7,
                }}
              >
                {report.subject}
              </Text>
              <View style={{ marginBottom: 2 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    paddingHorizontal: 7,
                  }}
                >
                  <Text style={{ fontSize: 13 }} variant="textLightMuted">
                    {FormatDate(report.timestamp)} &nbsp; &nbsp;{' '}
                    <Text variant="textMuted">Macher:in:</Text>{' '}
                    {editor.firstName} {editor.lastName}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: 20,
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <View style={{ paddingBottom: 5 }}>
              <Text
                style={{
                  textAlign: report.text.length > 120 ? 'left' : 'center',
                  paddingHorizontal: 7,
                }}
              >
                {report.text}
              </Text>
              {report.location && report.location.length > 0 ? (
                <View
                  style={{
                    alignItems: 'center',
                    marginTop: 20,
                    paddingHorizontal: 7,
                  }}
                >
                  <Text style={{ fontSize: 13 }}>
                    <Text variant="textLightMuted">Ort: </Text>
                    {report.location}
                  </Text>
                </View>
              ) : null}
            </View>
            {/* report.assets.length > 0 ? (
              <View
                style={{
                  backgroundColor: Colors[app.colorScheme].backgroundSepLight,
                }}
              >

                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                  <Assets assets={report.assets} maxThumbnailsPerRow={4} />
                </View>
              </View>
            ) : null */}

            {report.statusChanges.length > 0 ? (
              <View style={{ marginTop: 20 }}>
                {/* }<Text style={styles.title}>Status</Text> */}
                <View>
                  {report.statusChanges
                    .slice(0)
                    // .reverse()
                    .map((statusChange, index) => (
                      <StatusChange
                        key={statusChange.id}
                        id={statusChange.id}
                        status={statusChange.status}
                        timestamp={statusChange.timestamp}
                        user={statusChange.user}
                        comment={statusChange.comment}
                        firstItem={index === 0}
                        assets={statusChange.assets}
                        statusAssets={
                          Array.isArray(statusChange.assets)
                            ? report.assets.filter(
                                (el) =>
                                  statusChange.assets.indexOf(el.id) !== -1
                              )
                            : []
                        }
                      />
                    ))}
                </View>
              </View>
            ) : null}
            {report.editor === user.id ? (
              <StatusUpdate
                editor={report.editor}
                onStatusChange={updateStatus}
              />
            ) : (
              <AddComment onStatusChange={updateStatus} />
            )}
          </View>
        </View>
      </ScrollView>
      {isLoading && <LoadingOverlay text={isLoadingText}></LoadingOverlay>}
    </KeyboardAvoidingView>
  )
}

export type NewContact = {
  firstName: string
  lastName: string
  email: string
}

export type StatusUpdateData = {
  newStatus: number
  comment?: string
  newEditor?: number
  mailRecipient?: number
  newContact?: NewContact
  returnToOverview?: boolean
  files?: UploadFile[]
}

export default ReportDetail
