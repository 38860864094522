import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { setTerminalParams } from '../../redux/dfSlice'
import { StyleSheet } from 'react-native'
import { View } from '../../components/Themed'
import BackendRequest from '../../components/backendRequest'
import SettingsList, { SettingsItem } from '../settingsList/index'
import { DfAppSettingsStackScreenProps } from './index'

function Settings({
  route,
}: DfAppSettingsStackScreenProps<'dfAppSettings'>): JSX.Element {
  const dispatch = useAppDispatch()
  const dfState = useAppSelector(({ df }) => df)

  const settingsData: {
    title: string
    icon: string
    data: SettingsItem[]
  }[] = [
    {
      title: 'Umfrageterminal',
      icon: 'dashboard',
      data: [
        {
          name: 'interviewsMin',
          title: 'Interviews min.',
          value: dfState.terminalInterviewsPerHourMin,
          valueType: 'number',
          onChange: (val) => {
            dispatch(
              setTerminalParams({
                terminalGood: dfState.terminalInterviewsPerHourGood,
                terminalMin: typeof val !== 'number' ? Number(val) : val,
              })
            )
            BackendRequest('setDfAppSettings', {
              terminalGood: dfState.terminalInterviewsPerHourGood,
              terminalMin: val,
            })
          },
        },
        {
          name: 'interviewsGood',
          title: 'Interviews super',
          value: dfState.terminalInterviewsPerHourGood,
          valueType: 'number',
          onChange: (val) => {
            console.log('v', val)
            dispatch(
              setTerminalParams({
                terminalMin: dfState.terminalInterviewsPerHourMin,
                terminalGood: typeof val !== 'number' ? Number(val) : val,
              })
            )
            BackendRequest('setDfAppSettings', {
              terminalMin: dfState.terminalInterviewsPerHourMin,
              terminalGood: val,
            })
          },
        },
        {
          name: 'interviewsBool',
          title: 'Interviews bool',
          value: true,
          valueType: 'boolean',
          onChange: (val) => {
            console.log(val)
          },
        },
      ],
    },
  ]

  useEffect(() => {
    if (
      route.params.setSetting &&
      route.params.setSetting.type &&
      route.params.setSetting.name &&
      route.params.setSetting.value
    ) {
      if (route.params.setSetting.type === 'df')
        BackendRequest('setDfAppSettings', {
          setting: route.params.setSetting.name,
          value: route.params.setSetting.value,
        })
    }
  })

  return (
    <View
      style={{
        ...styles.container,
      }}
    >
      <SettingsList data={settingsData} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
    paddingBottom: 10,
    paddingTop: 11,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})

export default Settings
