import { View } from 'react-native'
import Colors, { themeColors } from '../constants/Colors'
import { useAppSelector } from '../redux/hooks'

export default function EmptyScreen(backgroundColor?: keyof themeColors) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const bgColor =
    Colors[colorScheme][
      backgroundColor ? backgroundColor : 'backgroundSepLight'
    ]
  return <View style={{ flex: 1, backgroundColor: bgColor }}></View>
}
