import React, { useState } from 'react'
import { View } from 'react-native'
import { Text, TextInput, ColorButton } from '../../../components/Themed'
import { Alert } from '../../../components/alert'
import ReportActionView from './reportActionView'
import * as ImagePicker from 'expo-image-picker'
import AddMedia from '../addMedia/index'
import LoadingOverlay from '../../../components/loadingOverlay'

import { UploadFile } from '../../../components/backendRequest'

import { StatusUpdateData } from './index'

function AddComment({ onStatusChange }: Props) {
  const [comment, setComment] = useState('')
  const [images, setImages] = useState<ImagePicker.ImageInfo[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingText, setIsLoadingText] = useState('Lädt')

  const handleStatusUpdate = () => {
    if (comment.length < 2) {
      Alert.alert('Kommentartext fehlt.')
      return
    }

    const uploadFiles: UploadFile[] = []
    for (const asset of images) {
      uploadFiles.push({
        uri: asset.uri,
        width: asset.width ? asset.width : undefined,
        height: asset.height ? asset.height : undefined,
        duration: asset.duration ? asset.duration : undefined,
      })
    }

    onStatusChange({
      newStatus: 10,
      comment,
      returnToOverview: true,
      files: uploadFiles,
    })
  }

  const handleAddMedia = (image: ImagePicker.ImageInfo) =>
    setImages([...images, image])

  return (
    <ReportActionView title="Meldung kommentieren">
      <Text style={{ marginTop: 15, marginBottom: 4, fontSize: 16 }}>
        Kommentar:
      </Text>
      <TextInput
        value={comment}
        onChangeText={setComment}
        multiline
        variant="background"
        style={{
          minHeight: 75,
          fontSize: 17,
        }}
      />

      <View>
        <Text style={{ marginTop: 28, marginBottom: 4, fontSize: 16 }}>
          Fotos (optional):
        </Text>

        {/* }<Text style={styles.title}>Medien</Text> */}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <AddMedia
            assets={images.filter((el) => (el ? true : false))}
            onAssetAdded={handleAddMedia}
          />
        </View>
      </View>
      <View
        style={{
          marginTop: 18,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <ColorButton variant="tint" onPress={handleStatusUpdate}>
          Speichern
        </ColorButton>
      </View>
      {isLoading && <LoadingOverlay text={isLoadingText} />}
    </ReportActionView>
  )
}

type Props = {
  onStatusChange: (arg0: StatusUpdateData) => void
}

export default AddComment
