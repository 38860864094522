import React from 'react'
import { View } from 'react-native'
import ImageViewer from 'react-simple-image-viewer'
import { ReportMedia } from '../machelSlice'

export default function ImageView({
  images,
  imageIndex,
  visible,
  onRequestClose,
  presentationStyle,
}: {
  images: Omit<ReportMedia, 'filename'>[]
  imageIndex: number
  visible: boolean
  onRequestClose?: () => void
  presentationStyle: string
}): JSX.Element {
  return visible ? (
    <ImageViewer
      src={images.filter((el) => el.uri !== undefined).map((el) => el.uri)}
      // keyExtractor={(imageSrc, index) => index.toString()}
      // animationType="none"
      currentIndex={imageIndex}
      onClose={onRequestClose}
      closeOnClickOutside={true}
    />
  ) : (
    <View></View>
  )
}
