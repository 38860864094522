import { FontAwesome } from '@expo/vector-icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useNavigation,
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React, { useEffect } from 'react'
import { useAppSelector } from '../redux/hooks'
import { Platform } from 'react-native'
import Colors, { tintColorDark, tintColorLight } from '../constants/Colors'
/// import useColorScheme from '../hooks/useColorScheme'
import NotFoundScreen from '../screens/NotFoundScreen'
import Login from '../screens/Login'
import PasswordForgotten from '../screens/PasswordForgotten'
import ResetPassword from '../screens/PasswordReset'
import Aktuell from '../features/aktuell/Aktuell'
import Account from '../features/account/index'
import Klicker from '../features/klicker/Klicker'
import EmergencyExits from '../features/emergExits'
import Schnack from '../features/schnack/index'
import Machel from '../features/machel/index'
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from '../types'
import LinkingConfiguration from './LinkingConfiguration'

export type NavigateToProp = {
  target: keyof RootStackParamList | null
  routeParams: Record<string, unknown>
}

function Navigation({ navigateTo }: { navigateTo: NavigateToProp }) {
  const { userToken, colorScheme } = useAppSelector(({ app, user }) => ({
    userToken: user.userToken,
    colorScheme: app.colorScheme,
  }))
  const theme =
    colorScheme === 'dark'
      ? {
          ...DarkTheme,
          colors: { ...DarkTheme.colors, primary: tintColorDark },
        }
      : {
          ...DefaultTheme,
          colors: { ...DefaultTheme.colors, primary: tintColorLight },
        }
  return (
    <NavigationContainer linking={LinkingConfiguration} theme={theme}>
      <RootNavigator userToken={userToken} navigateTo={navigateTo} />
    </NavigationContainer>
  )
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>()

type Props = {
  userToken: string | null
  navigateTo: {
    target: keyof RootStackParamList | null
    routeParams: Record<string, unknown>
  }
}

function RootNavigator({ userToken, navigateTo }: Props) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const navigation = useNavigation()
  useEffect(() => {
    if (navigateTo && navigateTo.target)
      navigation.navigate(
        navigateTo.target as never,
        navigateTo.routeParams
          ? (navigateTo.routeParams as never)
          : ({} as never)
      )
  }, [navigateTo])

  return (
    <Stack.Navigator
      screenOptions={{
        // headerTintColor: Colors[colorScheme].tint,
        headerTitleStyle: { color: Colors[colorScheme].text },
      }}
    >
      {userToken !== null ? (
        <>
          <Stack.Screen
            name="Root"
            component={BottomTabNavigator}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{ title: 'Oops!' }}
          />

          {/*
          <Stack.Group screenOptions={{ presentation: 'modal' }}>
            <Stack.Screen
              name="Modal"
              component={ModalScreen}
              options={{ headerShown: false }}
            />
          </Stack.Group>
          */}
        </>
      ) : (
        <>
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="PasswordForgotten"
            component={PasswordForgotten}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="ResetPassword"
            component={ResetPassword}
            options={{ headerShown: false }}
          />
        </>
      )}
    </Stack.Navigator>
  )
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>()

function BottomTabNavigator() {
  const isAdmin = useAppSelector(({ user }) => user.admin)
  return (
    <BottomTab.Navigator
      initialRouteName="Machel"
      screenOptions={
        {
          // tabBarActiveTintColor: Colors[colorScheme].tint,
        }
      }
    >
      {/*
      <BottomTab.Screen
        name="Aktuell"
        component={Aktuell}
        options={({ navigation }: RootTabScreenProps<'Aktuell'>) => ({
          title: 'Aktuell',
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="dashboard" color={color} />
          ),
          //
          // headerRight: () => (


          //   <Pressable
          //   <View style={{marginRight: 9}}>
          //     <Button title="Klickern" onPress={() => navigation.navigate('Klicker')}></Button>
          //   </View>
          //     onPress={() => navigation.navigate('Klicker')}
          //     style={({ pressed }) => ({
          //       opacity: pressed ? 0.5 : 1,
          //     })}>
          //     <Text><FontAwesome
          //       name="plus"
          //       size={25}
          //       color={Colors[colorScheme].text}
          //       style={{ marginLeft: 20 }}
          //     />Klickern</Text>
          //   </Pressable>

          // ),
        })}
      />
      <BottomTab.Screen
        name="Klicker"
        component={Klicker}
        options={({ navigation }: RootTabScreenProps<'Klicker'>) => ({
          title: 'Klicker',
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="thermometer-3" color={color} />
          ),
        })}
      />
      */}

      <BottomTab.Screen
        name="Machel"
        component={Machel}
        options={{
          title: 'Machel',
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="thumb-tack" color={color} />
          ),
        }}
      />

      {/*
      <BottomTab.Screen
        name="EmergencyExits"
        component={EmergencyExits}
        options={{
          title: 'Fluchtwege',
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="sign-out" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="Schnack"
        component={Schnack}
        options={{
          title: 'Schnack',
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="comments" color={color} />
          ),
        }}
      /> */}
      <BottomTab.Screen
        name="account"
        component={Account}
        options={{
          title: 'Account',
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="user" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  )
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name']
  color: string
}) {
  return (
    <FontAwesome
      size={Platform.OS === 'ios' ? 28 : 26}
      style={{ marginBottom: Platform.OS === 'ios' ? -2 : 0 }}
      {...props}
    />
  )
}

export default Navigation
