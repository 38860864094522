/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import React, { ForwardedRef } from 'react'
import { useAppSelector } from '../redux/hooks'
import {
  Text as DefaultText,
  View as DefaultView,
  Button as DefaultButton,
  TextInput as DefaultTextInput,
  Pressable,
  StyleProp,
  ViewStyle,
  TextStyle,
  RegisteredStyle,
} from 'react-native'
import Colors, { ColorNames } from '../constants/Colors'

// import useColorScheme from '../hooks/useColorScheme'

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: ColorNames // keyof typeof Colors.light & keyof typeof Colors.dark
): string {
  const theme = useAppSelector(({ app }) => app.colorScheme)
  const colorFromProps = props[theme]

  if (colorFromProps) {
    return colorFromProps
  } else {
    return Colors[theme][colorName]
  }
}

type ThemeProps = {
  lightColor?: string
  darkColor?: string
}

export type TextProps = ThemeProps &
  DefaultText['props'] & {
    variant?: ColorNames
    style?: (TextStyle | RegisteredStyle<TextStyle>) & { lineHeight?: number }
  }

export function Text(props: TextProps): JSX.Element {
  const { style, lightColor, darkColor, variant, ...otherProps } = props

  const color = useThemeColor(
    { light: lightColor, dark: darkColor },
    variant ? variant : 'text'
  )

  return (
    <DefaultText
      style={[
        {
          color,
          lineHeight: style && style.lineHeight ? style.lineHeight : undefined,
        },
        style,
      ]}
      {...otherProps}
    />
  )
}

export function Title(props: TextProps): JSX.Element {
  const { style, lightColor, darkColor, variant, ...otherProps } = props

  const color = useThemeColor(
    { light: lightColor, dark: darkColor },
    variant ? variant : 'text'
  )

  return (
    <DefaultText
      style={[{ color, fontWeight: 'bold', fontSize: 20 }, style]}
      {...otherProps}
    />
  )
}

export type ViewProps = ThemeProps &
  DefaultView['props'] & { variant: ColorNames }

export function View(props: ViewProps): JSX.Element {
  const { style, lightColor, darkColor, variant, ...otherProps } = props
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    variant ? variant : 'background'
  )

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />
}

export function ViewBg(props: ViewProps): JSX.Element {
  const { style, lightColor, darkColor, ...otherProps } = props
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'backgroundSep'
  )

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />
}

export type ButtonProps = { variant?: ColorNames } & ThemeProps &
  DefaultButton['props']

export function Button(props: ButtonProps): JSX.Element {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const { ...otherProps } = props
  const variant = props.variant ? props.variant : 'tint'
  return <DefaultButton color={Colors[colorScheme][variant]} {...otherProps} />
}

export function ColorButton({
  variant = 'tint',
  onPress,
  style,
  children,
}: {
  key?: string
  variant: ColorNames
  onPress: () => void
  style?: StyleProp<ViewStyle>
  children: React.ReactNode
}) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        style,
        {
          opacity: pressed ? 0.8 : 1,
        },
      ]}
    >
      <DefaultView
        style={{
          backgroundColor: Colors[colorScheme][variant],
          height: 52,
          paddingHorizontal: 19,
          borderRadius: 99,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={{ fontSize: 16, color: '#fff' }}>{children}</Text>
      </DefaultView>
    </Pressable>
  )
}

export function ColorButtonRow({ children }: { children: React.ReactElement }) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  return (
    <DefaultView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        paddingTop: 0,
        paddingBottom: 19,
        borderBottomWidth: 0.5,
        borderBottomColor: colorScheme === 'dark' ? '#222' : '#ddd',
      }}
    >
      <DefaultView
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        {children}
      </DefaultView>
    </DefaultView>
  )
}

export type TextInputProps = ThemeProps & DefaultTextInput['props']
export const TextInput = React.forwardRef(
  (
    props: TextInputProps & { variant?: ColorNames },
    ref: ForwardedRef<TextInputProps>
  ): JSX.Element => {
    const { style, lightColor, darkColor, ...otherProps } = props
    const elVariant = props.variant ? props.variant : 'backgroundSepLight'
    const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text')
    const colorScheme = useAppSelector(({ app }) => app.colorScheme)

    return (
      <DefaultTextInput
        ref={ref}
        style={[
          {
            color,
            fontSize: 23,
            paddingHorizontal: 19,
            paddingVertical: 11,
            marginVertical: 10,
            marginHorizontal: 5,
            backgroundColor: Colors[colorScheme][elVariant],
            borderRadius: 19,
          },
          style,
        ]}
        {...otherProps}
      />
    )
  }
)
