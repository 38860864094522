import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { Alert, Button, Platform, View } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import ImageResizer from 'react-native-image-resizer'
import AssetView from '../reportDetail/media'

export default function AddAssets({
  assets,
  onAssetAdded,
}: {
  assets: ImagePicker.ImageInfo[]
  onAssetAdded: (image: ImagePicker.ImageInfo) => void
}) {
  const fileInputRef = useRef<HTMLInputElement>()
  const [isSelectingImage, setIsSelectingImage] = useState(false)
  const [statusCamera, requestPermissionCamera] =
    ImagePicker.useCameraPermissions()
  const [statusLibrary, requestPermissionLibrary] =
    ImagePicker.useMediaLibraryPermissions()
  const [viewUpdateAlternator, setViewUpdateAlternator] = useState(false)

  const takePhotoAsync = async () => {
    // Display the camera to the user and wait for them to take a photo or to cancel
    // the action

    setIsSelectingImage(true)

    const result = await ImagePicker.launchCameraAsync({
      // allowsEditing: true,
      // mediaTypes: ImagePicker.MediaTypeOptions.All,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      // quality: 0.5,
      videoQuality: ImagePicker.UIImagePickerControllerQualityType.Medium,
      // videoMaxDuration: 120,
      rotation: 360,
    }).catch((e) => {
      console.log('launching ImagePicker failed')
      return
    })

    console.log('imgPicker', result)
    if (result?.cancelled) {
      console.log('ImageInput: image picker cancelled')

      return
    }

    if (result?.uri) {
      let imageUri = result.uri
      if (result.originalRotation) {
        let rotation = 0

        if (result.originalRotation === 90) {
          rotation = 90
        } else if (result.originalRotation === 270) {
          rotation = -90
        }

        ImageResizer.createResizedImage(
          result.uri,
          2000,
          2000,
          'JPEG',
          80,
          rotation
        )
          .then(({ uri }) => {
            imageUri = uri
          })
          .catch((err) => {
            console.log(err)

            return Alert.alert(
              'Unable to resize the photo',
              'Please try again!'
            )
          })
      }
      setIsSelectingImage(false)
      onAssetAdded({ ...result, uri: imageUri })
    } else {
      console.log('machel: add: photo: no asset transmitted from image picker')
    }
  }

  const takePhotoNative = () => {
    if (statusCamera?.granted && statusLibrary?.granted) {
      if (!isSelectingImage) takePhotoAsync()
    } else {
      if (!statusCamera?.granted && statusCamera?.canAskAgain)
        requestPermissionCamera()

      if (!statusLibrary?.granted && statusLibrary?.canAskAgain)
        requestPermissionLibrary()
    }
  }

  const handleWebInputChange = () => {
    // console.log('change')
    // if (
    //   typeof event.target.value !== 'string' ||
    //   event.target.value.length === 0 ||
    //   event.target.value.substring(3, 11) === 'fakepath'
    // ) {
    //   console.log('ImageInput: empty input')
    if (fileInputRef && fileInputRef?.current?.files?.length > 0) {
      const fReader = new FileReader()
      fReader.readAsDataURL(fileInputRef.current.files[0])
      fReader.onloadend = (e) => {
        if (e.target?.result) {
          console.log('imageInput: fileReader', e.target)
          if (typeof e.target.result === 'string') {
            const returnImage = { uri: e.target.result, width: 0, height: 0 }
            const img = new Image()
            img.src = e.target.result
            img.onload = () => {
              returnImage.width = img.width
              returnImage.height = img.height
              onAssetAdded(returnImage)
            }
          } else {
            console.log('machel addMedia: image base url is no string')
          }
        } else {
          console.log('machel addMedia: file reader error')
        }
      }
      fReader.onerror = (e) =>
        console.log('machel addMedia: fileReader: reading file error')
    } else {
      console.log('imageInput: input not defined')
    }
    setIsSelectingImage(false)
    //   return
    // }
    // const imageResultUri = event.target.value
    // onAssetAdded(imageResultUri)
  }

  const selectPhotoWeb = () => {
    if (isSelectingImage) return
    if (fileInputRef.current && fileInputRef.current.click) {
      setIsSelectingImage(true)
      fileInputRef.current.click()
    }
  }

  const handleAddItem = () => {
    if (Platform.OS === 'web') {
      takePhotoNative()
      // selectPhotoWeb()
      // if (fileInputRef.current && fileInputRef.current.click)
      //   fileInputRef.current.click()
    } else {
      takePhotoNative()
    }
  }

  useEffect(() => {
    if (fileInputRef?.current) {
      fileInputRef.current.addEventListener('change', handleWebInputChange)
    }
  }, [fileInputRef])

  return (
    <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
      <AssetView
        assets={assets
          .filter((el) => (el ? true : false))
          .map((el, index) => ({
            id: index,
            uri: el.uri,
            index,
            filename: el.uri.split('/').pop(),
            type: el.type ? el.type : 'image',
          }))}
        showAddButton={true}
        onAdd={handleAddItem}
        style={{}}
      />

      <View style={{ width: 0, height: 0, overflow: 'hidden' }}>
        {Platform.OS === 'web' && (
          <input type="file" id="f1" accept="image/*" ref={fileInputRef} />
        )}
      </View>
    </View>
  )
}
