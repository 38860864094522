import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { setAppUpdateId, setShowUpdateText } from '../redux/appSlice'
import { Alert, View } from 'react-native'
import * as Updates from 'expo-updates'
import UpdateInfoModal from '../components/updateInfoModal'

const AppUpdater = () => {
  const { appUpdateId, showUpdateText } = useAppSelector(({ app }) => ({
    appUpdateId: app.appUpdateId,
    showUpdateText: app.showUpdateText,
  }))

  const dispatch = useAppDispatch()
  const [showUpdateInfoModal, setShowUpdateInfoModal] = useState(false)

  useEffect(() => {
    Updates.addListener((e) => {
      if (e.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
        Alert.alert(
          'Update geladen',
          'App neu starten, um das Update anzuwenden:',
          [
            {
              text: 'Neustart',
              onPress: () => Updates.reloadAsync(),
            },
          ]
          // { cancelable: true }
        )
        dispatch(setShowUpdateText(true))
      }
    })

    if (appUpdateId && Updates.updateId && appUpdateId !== Updates.updateId) {
      //TODO: Update Info Modal not working (showUpdateText updated too late, appUpdateId comparison too early)
      dispatch(setAppUpdateId(Updates.updateId))
      setShowUpdateInfoModal(true)
      dispatch(setShowUpdateText(false))
    }
  }, [])

  return showUpdateInfoModal ? (
    <UpdateInfoModal
      visible={showUpdateInfoModal}
      onClose={() => setShowUpdateInfoModal(false)}
    />
  ) : (
    <View></View>
  )
}

export default AppUpdater
