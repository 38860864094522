import React from 'react'
import { useAppSelector } from '../../../redux/hooks'
import { Platform, View, Pressable } from 'react-native'
import { Text } from '../../../components/Themed'
import FormatDate from '../../../components/formatDate'
import { FontAwesome } from '@expo/vector-icons'
import { Report } from '../machelSlice'
type Props = {
  report: Report
  adminView: boolean
  onClick: (reportId: number) => void
}

function MachelListItem(props: Props): JSX.Element {
  const { colorScheme, reportStatuses } = useAppSelector(({ app, machel }) => ({
    colorScheme: app.colorScheme,
    reportStatuses: machel.statuses,
  }))

  const { id, user, timestamp, subject, statusChanges } = props.report
  let currentStatus = 0
  if (statusChanges && statusChanges.length > 0) {
    currentStatus = statusChanges[statusChanges.length - 1].status
  }

  return (
    <Pressable onPress={() => props.onClick(id)}>
      <View
        style={{
          paddingTop: 15,
          paddingBottom: 13,
          paddingLeft: 12,
          paddingRight: 5,
          marginVertical: 0,
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexGrow: 1,
          flexShrink: 1,
          borderBottomWidth: 0.5,
          backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
          borderBottomColor: colorScheme === 'dark' ? '#222' : '#ddd',
        }}
      >
        <View
          style={{
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: 5,
            paddingTop: 0,
            width: 33,
          }}
        >
          {reportStatuses.length > 0 && (
            <FontAwesome
              size={Platform.OS === 'ios' ? 24 : 22}
              style={{ marginTop: -1 }}
              name={
                reportStatuses.filter((el) => el.id === currentStatus)[0].symbol
              }
              color={
                reportStatuses.filter((el) => el.id === currentStatus)[0].color
              }
            />
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexGrow: 1,
            flexShrink: 1,
            paddingLeft: 10,
          }}
        >
          <View style={{ paddingTop: 1, paddingBottom: 9, paddingRight: 6 }}>
            <Text
              style={{
                // fontWeight: 'bold',
                fontSize: 15,
                textAlign: 'left',
              }}
            >
              {subject}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 6,
              paddingRight: 10,
            }}
          >
            <Text
              variant="textMuted"
              style={{
                flex: 1,
                fontSize: 12,
                paddingRight: 5,
                textAlign: 'left',
              }}
            >
              {FormatDate(timestamp)}
            </Text>

            <Text variant="textMuted" style={{ flex: 1, fontSize: 12 }}>
              {user.firstName} {user.lastName}
            </Text>
          </View>
        </View>

        <View
          style={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            paddingRight: 5,
            width: 30,
          }}
        >
          <Text>
            <FontAwesome
              size={15}
              name="chevron-right"
              style={{ color: '#777' }}
            />
          </Text>
        </View>
      </View>
    </Pressable>
  )
}

export default MachelListItem
