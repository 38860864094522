export function RandomString(length: number, onlyNumbers = false): string {
  let result = ''
  const characters: string = onlyNumbers
    ? '0123456789'
    : 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789123456789'
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}
export default RandomString
