import { useState, useEffect } from 'react'
import { useAppSelector } from '../redux/hooks'
import Colors from '../constants/Colors'

export default function Picker({
  items,
  onValueChange,
  selectedValue,
}: {
  items: { value: string | number; label: string }[]
  onValueChange: (value: number | string) => void
  selectedValue?: number | string | null
}) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const [selected, setSelected] = useState<number | string | undefined>(
    selectedValue ? selectedValue.toString() : undefined
  )
  const [valueType, setValueType] = useState('number')

  const handleChange = (event: { target: { value: string } }) => {
    setSelected(event.target.value)
    onValueChange(
      valueType === 'number' ? Number(event.target.value) : event.target.value
    )
  }

  useEffect(() => {
    let itemsType = 'number'
    items.forEach((item) => {
      if (typeof item.value !== 'number') itemsType = 'string'
    })
    setValueType(itemsType)
  }, [items])
  return (
    <select onChange={handleChange}>
      {items.map((item, index) => (
        <option
          key={index}
          value={item.value.toString()}
          selected={item.value.toString() === selected ? true : undefined}
        >
          {item.value.toString()}
        </option>
      ))}
    </select>
  )
}
