import { useState, useEffect } from 'react'
import { LayoutAnimation, Platform, UIManager, View } from 'react-native'
import { Text, TextInput, Button } from '../../../components/Themed'
import { NewContact } from './index'

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true)
}

const AddContact = ({
  onChange,
  onAbort,
  show,
  showReturnButton = true,
}: {
  onChange: ({ firstName, lastName, email }: NewContact) => void
  onAbort: () => void
  show: boolean
  showReturnButton: boolean
}) => {
  const [expanded, setExpanded] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const toggleView = (val: boolean) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    setExpanded(val)
  }

  const handleEmailChange = (val: string) => {
    setEmail(val)
    onChange({ firstName, lastName, email: val })
  }

  const updateParentValues = () => {
    onChange({ firstName, lastName, email })
  }

  useEffect(() => {
    toggleView(show)
  }, [show])

  /*
  useEffect(() => {
    onChange({ firstName, lastName, email })
  }, [firstName, lastName, email])
  */

  return (
    <View
      style={{
        marginTop: 10,
      }}
    >
      {show && (
        <View>
          <Text style={{ marginLeft: 10, marginBottom: 3, fontSize: 16 }}>
            Vorname
          </Text>
          <TextInput
            variant="background"
            value={firstName}
            onChangeText={setFirstName}
            autoCapitalize="sentences"
            onEndEditing={updateParentValues}
            autoCorrect={false}
            style={{
              fontSize: 17,
            }}
          />
          <Text style={{ marginLeft: 10, marginBottom: 3, fontSize: 16 }}>
            Nachname
          </Text>
          <TextInput
            variant="background"
            value={lastName}
            onChangeText={setLastName}
            autoCapitalize="sentences"
            onEndEditing={updateParentValues}
            autoCorrect={false}
            style={{
              fontSize: 17,
            }}
          />
          <Text
            style={{
              marginTop: 10,
              marginBottom: 3,
              marginLeft: 10,
              fontSize: 16,
            }}
          >
            E-Mail
          </Text>
          <TextInput
            variant="background"
            value={email}
            keyboardType="email-address"
            autoCapitalize="none"
            onChangeText={handleEmailChange}
            autoCorrect={false}
            style={{
              fontSize: 17,
            }}
          />
          {showReturnButton && (
            <Button title="Zurück zur Kontaktliste" onPress={onAbort} />
          )}
        </View>
      )}
    </View>
  )
}

export default AddContact
