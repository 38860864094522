import React from 'react'
import { View } from 'react-native'
import { useAppSelector } from '../../../redux/hooks'
import { Text } from '../../../components/Themed'
import Colors from '../../../constants/Colors'

export default function ReportActionView({
  title,
  children,
}: {
  title?: string
  children: React.ReactNode
}) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  return (
    <View
      style={{
        marginHorizontal: 1,
        padding: 12,
        paddingBottom: 16,
        marginTop: 25,
        marginBottom: 20,
        borderRadius: 20,

        backgroundColor: Colors[colorScheme].backgroundSep,
      }}
    >
      {title && (
        <Text
          variant="textLightMuted"
          style={{
            fontSize: 13.5,
            fontWeight: 'bold',
            letterSpacing: 2,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 23,
            paddingHorizontal: 4,
          }}
        >
          {title.toUpperCase()}
        </Text>
      )}
      {children}
    </View>
  )
}
