import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as ImagePicker from 'expo-image-picker'
import { FontAwesome } from '@expo/vector-icons'

export type FontAwesomeSymbol = keyof typeof FontAwesome.glyphMap
export interface ReportStatus {
  id: number
  description: string
  setByEditor: boolean
  symbol: FontAwesomeSymbol
  color: string
}

export interface StatusChange {
  id: number
  timestamp: string
  status: number
  user: {
    id: number
    firstName: string
    lastName: string
  }
  comment: string | null
  assets: number[]
}

export interface Editor {
  id: number
  userId: number
  defaultEditor: boolean
  firstName: string
  lastName: string
}

export interface Report {
  id: number
  user: {
    id: number
    firstName: string
    lastName: string
  }
  timestamp: string
  subject: string
  text: string
  location?: string
  assets: ReportMedia[]
  statusChanges: StatusChange[]
  editor: number
}

export interface NewReport {
  id?: number
  user: {
    id: number
    firstName: string
    lastName: string
  }
  subject: string
  text: string
  location: string
  assets: ImagePicker.ImageInfo[]
}

export interface ReportMedia {
  id: number
  report?: number
  type: 'image' | 'video'
  width?: number
  height?: number
  duration?: number
  filename: string
  uri?: string
  comment?: string
}

export interface NewReportMedia {
  type: 'image' | 'video'
  width?: number
  height?: number
  duration?: number
  filename: string
  comment?: string
}

export interface MachelState {
  cameraAccess: boolean | null
  statuses: ReportStatus[]
  editors: Editor[]
  reports: Report[]
  reportsUpToDate: boolean
  updateReportsOnReturn: boolean
  addingNewReport: boolean
  newReport: NewReport
}

const initialState: MachelState = {
  cameraAccess: null,
  statuses: [],
  reports: [],
  reportsUpToDate: false,
  editors: [],
  addingNewReport: false,
  updateReportsOnReturn: false,
  newReport: {
    user: { id: 0, firstName: '', lastName: '' },
    subject: '',
    text: '',
    location: '',
    assets: [],
  },
}

export const machelSlice = createSlice({
  name: 'machel',
  initialState,
  reducers: {
    setCameraAccess: (state, action: PayloadAction<boolean>) => {
      state.cameraAccess = action.payload
    },
    setStatuses: (state, action: PayloadAction<ReportStatus[]>) => {
      state.statuses = action.payload
    },
    setEditors: (state, action: PayloadAction<Editor[]>) => {
      state.editors = action.payload
    },
    setReports: (state, action: PayloadAction<Report[]>) => {
      state.reports = action.payload
      state.updateReportsOnReturn = false
      state.reportsUpToDate = true
    },
    setReportsUpToDate: (state, action: PayloadAction<boolean>) => {
      state.reportsUpToDate = action.payload
    },
    setUpdateOnReturn: (state, action: PayloadAction<boolean>) => {
      state.updateReportsOnReturn = action.payload
    },
    setAddingNewReport: (state, action: PayloadAction<boolean>) => {
      state.addingNewReport = action.payload
      if (action.payload === true)
        state.newReport = {
          user: { id: 0, firstName: '', lastName: '' },
          subject: '',
          text: '',
          location: '',
          assets: [],
        }
    },
    setNewReportUser: (
      state,
      action: PayloadAction<{ id: number; firstName: string; lastName: string }>
    ) => {
      state.newReport.user = action.payload
    },
    setNewReportSubject: (state, action: PayloadAction<string>) => {
      state.newReport.subject = action.payload
    },
    setNewReportText: (state, action: PayloadAction<string>) => {
      state.newReport.text = action.payload
    },
    setNewReportLocation: (state, action: PayloadAction<string>) => {
      state.newReport.location = action.payload
    },
    addNewReportAsset: (
      state,
      action: PayloadAction<ImagePicker.ImageInfo>
    ) => {
      if (!state.newReport.assets) state.newReport.assets = []
      state.newReport.assets = state.newReport.assets.filter((el) =>
        el ? true : false
      )
      state.newReport.assets.push(action.payload)
    },
    reset: (state, action: PayloadAction<{ startNewReport?: boolean }>) => {
      state.addingNewReport = action.payload.startNewReport ? true : false
      state.newReport = {
        user: { id: 0, firstName: '', lastName: '' },
        subject: '',
        text: '',
        location: '',
        assets: [],
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCameraAccess,
  setStatuses,
  setEditors,
  setReports,
  setReportsUpToDate,
  setUpdateOnReturn,
  setAddingNewReport,
  setNewReportUser,
  setNewReportSubject,
  setNewReportText,
  setNewReportLocation,
  addNewReportAsset,
  reset,
} = machelSlice.actions

export default machelSlice.reducer
