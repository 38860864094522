import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import appReducer from './appSlice'
import userReducer from './userSlice'
import dfReducer from './dfSlice'
// import klickerReducer from '../features/klicker/klickerSlice'
// import emergExitReducer from '../features/emergExits/emergExitSlice'
import machelReducer from '../features/machel/machelSlice'

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  df: dfReducer,
  // klicker: klickerReducer,
  // emergExit: emergExitReducer,
  machel: machelReducer,
})

const persistConfig = { key: 'root', version: 1, storage: AsyncStorage }

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

/*
export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    df: dfReducer,
    klicker: klickerReducer,
    emergExit: emergExitReducer,
  },
})
*/
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState> // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
