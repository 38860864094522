import Moment from 'moment'
import 'moment/locale/de'
Moment.locale('de')

function FormatDate(timestamp: string) {
  const elementDate = Moment(timestamp)

  return (
    (Moment().format('DDMMYY') === elementDate.format('DDMMYY')
      ? 'Heute'
      : Moment().subtract(1, 'days').format('DDMMYY') ===
        elementDate.format('DDMMYY')
      ? 'Gestern'
      : elementDate.format('DD.MM.YY')) +
    ' ' +
    elementDate.format('HH:mm')
  )
}

export default FormatDate
