import React from 'react'
import {
  View,
  GestureResponderEvent,
  Linking,
  TouchableOpacity,
  Switch,
  Animated,
} from 'react-native'
import { Text } from '../../components/Themed'
import { FontAwesome } from '@expo/vector-icons'
import Colors from '../../constants/Colors'
import { SettingsListStackScreenProps } from './index'
import { useAppSelector } from '../../redux/hooks'

export interface SettingsItem {
  name: string
  title: string
  value: string | number | boolean | null
  valueType: 'string' | 'number' | 'boolean'
  onChange: (val: string | number | boolean) => void
}
type Props = {
  item: SettingsItem
  isFirstElement?: boolean
  isLastElement?: boolean
} & SettingsListStackScreenProps<'List'>

export const SettingsListItem = (props: Props): JSX.Element => {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const { navigation, item } = props
  return (
    <StyledSettingsListItemWrapper
      {...props}
      onPress={
        item.valueType !== 'boolean'
          ? () => navigation.navigate('ItemDetail', { item })
          : undefined
      }
    >
      <Text>{props.item.title}</Text>

      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          // backgroundColor: Colors[colorScheme].backgroundSep,
        }}
      >
        {/* If value is string or numeric, show value, in case of boolean a
          switch */}

        {props.item.valueType === 'boolean' ? (
          <View>
            <Switch
              style={{ flex: 1 }}
              trackColor={{
                false: colorScheme === 'dark' ? '#777' : '#555',
                true: 'green',
              }}
              thumbColor="white"
              onValueChange={() => props.item.onChange(!props.item.value)}
              value={
                typeof props.item.value === 'boolean' ? props.item.value : false
              }
            />
          </View>
        ) : (
          <View
            style={{
              // backgroundColor: Colors[colorScheme].backgroundSep,
              // transform: [{ rotate: '180deg' }],
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingVertical: 2,
            }}
          >
            <Text>{props.item.value ? props.item.value.toString() : ''}</Text>
            <FontAwesome
              size={15}
              name="chevron-right"
              style={{ color: '#777', marginLeft: 18 }}
            />
          </View>
        )}
      </View>
    </StyledSettingsListItemWrapper>
  )
}

const actionForMenuItem = (item: SettingsItem) => {
  switch (item.name) {
    case 'aboutMe': {
      Linking.openURL('aboutMe')
      break
    }
    case 'help': {
      Linking.openURL('help')
      break
    }
    case 'spaceAPI': {
      Linking.openURL('spaceApiUrl')
      break
    }
    case 'review': {
      console.log('inappreview not available')
    }
  }
}

const StyledSettingsListItemWrapper = ({
  isFirstElement = false,
  isLastElement = false,
  onPress,
  children,
}: {
  isFirstElement?: boolean
  isLastElement?: boolean
  onPress?: (event: GestureResponderEvent) => void
  children: JSX.Element | JSX.Element[]
}) => {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
        marginHorizontal: 2,
        backgroundColor: Colors[colorScheme].backgroundSep,
        borderTopLeftRadius: isFirstElement ? 16 : 0,
        borderTopRightRadius: isFirstElement ? 16 : 0,
        borderBottomLeftRadius: isLastElement ? 16 : 0,
        borderBottomRightRadius: isLastElement ? 16 : 0,
      }}
    >
      {children}
    </TouchableOpacity>
  )
}
