import React, { useState, useEffect, MutableRefObject, useRef } from 'react'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { View, Platform } from 'react-native'
import { setExpoPushToken } from '../redux/userSlice'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'
import BackendRequest from './backendRequest'
import { NavigateToProp } from '../navigation'

const AppNotifications = ({
  onNavigateTo,
}: {
  onNavigateTo?: NavigateToProp
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(({ user }) => user)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notificationListener = useRef(null) as MutableRefObject<null | any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseListener = useRef(null) as MutableRefObject<null | any>
  const [notification, setNotification] = useState<
    Notifications.Notification | boolean
  >(false)

  const loadResourcesAndDataAsync = async () => {
    try {
      if (Platform.OS !== 'web') {
        Notifications.setBadgeCountAsync(0)
        registerForPushNotificationsAsync()
        // This listener is fired whenever a notification is received while the app is foregrounded
        notificationListener.current =
          Notifications.addNotificationReceivedListener((notification) => {
            setNotification(notification)
          })

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        responseListener.current =
          Notifications.addNotificationResponseReceivedListener((response) => {
            console.log(response.notification.request.content)
            if (onNavigateTo) {
              if (
                response.notification.request.content.data.navigate &&
                typeof response.notification.request.content.data.navigate ===
                  'string'
              ) {
                onNavigateTo({
                  target: response.notification.request.content.data.navigate,
                  routeParams: response.notification.request.content.data
                    .routeParams
                    ? response.notification.request.content.data.routeParams
                    : {},
                })
              } else {
                onNavigateTo({ target: 'Aktuell' })
              }
            } else onNavigateTo({ target: 'Aktuell' })
          })
      }
    } catch (e) {
      // We might want to provide this error information to an error reporting service
      console.warn(e)
    }
  }

  const registerForPushNotificationsAsync = async () => {
    if (user.id !== null && Constants.isDevice && Platform.OS !== 'web') {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        alert('Fehler: Push-Nachrichten konnten nicht aktiviert werden.')
        return
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data
      dispatch(setExpoPushToken(token))

      BackendRequest('userChangeSetting', {
        field: 'expoPushToken',
        value: token,
      })
        .then((data: { success: boolean; message?: string }) => {
          if (!data.success) {
            console.log('apiError: ', data.message)
            return false
          } else {
            return
          }
        })
        .catch((error: string) => console.log(error))
    } else {
      console.log('Must use physical device for Push Notifications')
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      })
    }
  }

  useEffect(() => {
    loadResourcesAndDataAsync()

    return () => {
      if (Platform.OS !== 'web') {
        if (notificationListener.current)
          Notifications.removeNotificationSubscription(
            notificationListener.current
          )
        if (responseListener.current)
          Notifications.removeNotificationSubscription(responseListener.current)
      }
    }
  }, [])

  return <View></View>
}

export default AppNotifications
