import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import BackendRequest, {
  ApiReturnValue,
  UploadFile,
} from '../../../components/backendRequest'
import {
  Animated,
  LayoutAnimation,
  Platform,
  StyleSheet,
  UIManager,
  View,
} from 'react-native'
import {
  Text,
  TextInput,
  ColorButton,
  Button,
} from '../../../components/Themed'
import { Alert } from '../../../components/alert'
import ReportActionView from './reportActionView'
import AddMedia from '../addMedia/index'
import * as ImagePicker from 'expo-image-picker'
import Picker from '../../../components/picker'
import AddContact from './editStatus_addContact'
import StatusIcon from './editStatus_statusIcon'
import { NewContact, StatusUpdateData } from './index'

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true)
}

function StatusUpdate({
  onStatusChange,
  editor,
}: {
  onStatusChange: (arg0: StatusUpdateData) => void
  editor: number
}) {
  const { app, machel, user } = useAppSelector(({ app, machel, user }) => ({
    app,
    machel,
    user,
  }))

  const [status, setStatus] = useState<number | null>(
    machel.statuses && machel.statuses.length > 0
      ? machel.statuses.filter((el) => el.setByEditor)[0].id
      : null
  )
  const [comment, setComment] = useState('')
  const [newEditor, setNewEditor] = useState<number | null>(null)
  const [mailRecipient, setMailRecipient] = useState<number | null>(null)
  const [newContact, setNewContact] = useState<{
    firstName: string
    lastName: string
    email: string
  }>({ firstName: '', lastName: '', email: '' })
  const [images, setImages] = useState<ImagePicker.ImageInfo[]>([])

  const [showAddContact, setShowAddContact] = useState(false)
  const [editorMailContacts, setEditorMailContacts] = useState<
    EditorMailContact[]
  >([])

  const handleStatusUpdate = () => {
    const missingFields = []
    if (newContact.firstName.length === 0) missingFields.push('Vorname')
    if (newContact.lastName.length === 0) missingFields.push('Nachname')
    if (newContact.email.length === 0) missingFields.push('E-Mail')

    if (status === null) {
      Alert.alert('Bitte Meldungsstatus wählen')
      return
    }
    if (status === 6 && newEditor === null) {
      Alert.alert('Bitte neuen Macher wählen')
      return
    }

    if (status === 7 && mailRecipient === null) {
      if (missingFields.length === 3) {
        Alert.alert(
          'Bitte einen Kontakt auswählen oder einen neuen hinzufügen.'
        )
        return
      }
      if (missingFields.length > 0) {
        let alertText =
          'Bitte vollständige Daten für neuen Kontakt eingeben. Es '
        alertText += missingFields.length === 1 ? 'fehlt:' : 'fehlen:'
        alertText += missingFields.map((el, index) => {
          return (index > 1 ? ',' : '') + ' ' + el
        })
        Alert.alert(alertText)
        return
      }
      if (
        editorMailContacts.filter((el) => el.email === newContact.email)
          .length > 0
      ) {
        const existingContact = editorMailContacts.filter(
          (el) => el.email === newContact.email
        )[0]
        Alert.alert(
          `Du hast bereits einen Kontakt mit dieser E-Mail: ${existingContact.firstName} ${existingContact.lastName}`
        )
        return
      }
    }

    const uploadFiles: UploadFile[] = []
    for (const asset of images) {
      uploadFiles.push({
        uri: asset.uri,
        width: asset.width ? asset.width : undefined,
        height: asset.height ? asset.height : undefined,
        duration: asset.duration ? asset.duration : undefined,
      })
    }

    onStatusChange({
      newStatus: status,
      comment,
      newEditor: newEditor ? newEditor : undefined,
      mailRecipient: mailRecipient ? mailRecipient : undefined,
      newContact,
      returnToOverview: true,
      files: uploadFiles,
    })
  }

  const handleAddNewContact = () => {
    setMailRecipient(null)
    setShowAddContact(true)
  }

  const handleNewContactChange = (newContact: NewContact) => {
    setNewContact(newContact)
    setMailRecipient(null)
  }

  const fetchEditorMailContacts = () => {
    BackendRequest('machel/editor/getcontacts', { editorId: editor })
      .then((data: BackendEditorMailContactsData) => {
        if (data.success && data.contacts) {
          setEditorMailContacts(data.contacts)
          if (data.contacts && data.contacts.length > 0) {
            setShowAddContact(false)
            setMailRecipient(data.contacts[0].id)
          } else {
            setShowAddContact(true)
          }
        } else {
          Alert.alert('E-Mail-Kontakte konnten nicht geladen werden.')

          console.log(
            'machel report detail editor contacts fetch failed' +
              (data.message ? data.message : '')
          )
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        Alert.alert('E-Mail-Kontakte konnten nicht geladen werden.')
        console.log(
          'machel: reportDetail: stausUpdate: fetchContacts error: ',
          error
        )
      })
  }

  const handleAssetAdded = (image: ImagePicker.ImageInfo) =>
    setImages([...images, image])

  useEffect(() => {
    fetchEditorMailContacts()
  }, [])

  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  })

  return (
    <ReportActionView title="Meldung bearbeiten">
      <Text style={{ marginTop: 15, marginBottom: 20, fontSize: 16 }}>
        Neuer Status:
      </Text>
      <View style={styles.statusRow}>
        {machel.statuses
          .filter((el) => el.setByEditor)
          .map((el) => (
            <Animated.View key={el.id}>
              <StatusIcon
                key={el.id}
                active={el.id === status}
                status={el}
                onClick={setStatus}
              />
            </Animated.View>
          ))}
      </View>
      <View style={{ marginTop: 19 }}>
        <Text style={styles.textCenter}>
          {status === null
            ? ''
            : machel.statuses.filter((el) => el.id === status)[0].description}
        </Text>
      </View>

      {/* reassigned */}
      {status === 6 && (
        <View style={{ marginTop: 39, flexDirection: 'column' }}>
          <Text style={{ marginBottom: 5, fontSize: 16 }}>Neuer Macher:</Text>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
            }}
          >
            {machel.editors
              .filter((el) => el.userId !== user.id && el.userId !== editor)
              .map((editor) => (
                <Button
                  key={editor.userId}
                  onPress={() => setNewEditor(editor.userId)}
                  title={editor.firstName + ' ' + editor.lastName}
                  variant={newEditor === editor.userId ? 'text' : 'tint'}
                ></Button>
              ))}
          </View>
        </View>
      )}

      {/* forward  */}
      {status === 7 && (
        <View style={{ marginTop: 39, flexDirection: 'column' }}>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginBottom: 5, fontSize: 16 }}>
              {'Per Mail ' +
                (showAddContact ? 'an neuen Kontakt ' : '') +
                'weiterleiten:'}
            </Text>
            <Button
              onPress={handleAddNewContact}
              title={!showAddContact ? 'Neuer Kontakt' : ' '}
            />
          </View>
          <View>
            <AddContact
              onChange={handleNewContactChange}
              onAbort={() => setShowAddContact(false)}
              show={showAddContact}
              showReturnButton={editorMailContacts.length > 0}
            />

            {editorMailContacts.length > 0 && !showAddContact && (
              <View
                style={
                  Platform.OS === 'ios'
                    ? { marginTop: -13, marginBottom: -13 }
                    : {}
                }
              >
                {Platform.OS === 'web' ? (
                  <select
                    onChange={(event) =>
                      setMailRecipient(parseInt(event.target.value))
                    }
                  >
                    {editorMailContacts.map((contact) => (
                      <option
                        key={contact.id}
                        label={`${contact.firstName} ${contact.lastName}`}
                        value={contact.id}
                      >{`${contact.firstName} ${contact.lastName}`}</option>
                    ))}
                  </select>
                ) : (
                  <Picker
                    selectedValue={mailRecipient}
                    onValueChange={(itemValue) =>
                      setMailRecipient(Number(itemValue))
                    }
                    items={editorMailContacts.map((contact) => ({
                      value: contact.id,
                      label: `${contact.firstName} ${contact.lastName}`,
                    }))}
                  />
                )}
                {/*
                  TODO: Checkbox ob Mail-Weiterleitung-StatusUpdate in der
                  Meldung angezeigt werden soll - Standard JA
                */}
              </View>
            )}
          </View>
        </View>
      )}

      <View style={{ marginTop: 39, flexDirection: 'column' }}>
        <Text style={{ marginBottom: 4, fontSize: 16 }}>
          Kommentar (optional):
        </Text>
        <TextInput
          variant="background"
          value={comment}
          onChangeText={setComment}
          multiline
          style={{
            minHeight: 75,
            fontSize: 17,
          }}
        />
      </View>
      <View>
        <Text style={{ marginBottom: 4, fontSize: 16 }}>Fotos (optional)</Text>

        {/* }<Text style={styles.title}>Medien</Text> */}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <AddMedia
            assets={images.filter((el) => (el ? true : false))}
            onAssetAdded={handleAssetAdded}
          />
        </View>
      </View>
      <View
        style={{
          marginTop: 18,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <ColorButton variant="tint" onPress={handleStatusUpdate}>
          Speichern
        </ColorButton>
      </View>
    </ReportActionView>
  )
}

type EditorMailContact = {
  id: number
  firstName: string
  lastName: string
  email: string
}

interface BackendEditorMailContactsData extends ApiReturnValue {
  contacts?: {
    id: number
    firstName: string
    lastName: string
    email: string
  }[]
}

export default StatusUpdate

const styles = StyleSheet.create({
  title1: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 23,
    paddingHorizontal: 4,
  },
  statusRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  textCenter: {
    textAlign: 'center',
  },
  statusButtonInactive: {
    borderColor: 'rgba(0, 0, 0, 0)',
  },
  statusButtonActive: {
    borderColor: '#ffffff',
  },
})
