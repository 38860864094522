import React, { useEffect, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { Button } from '../../components/Themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import BackendRequest, { ApiReturnValue } from '../../components/backendRequest'
import {
  setStatuses,
  setEditors,
  setReports,
  reset,
  FontAwesomeSymbol,
  Editor,
} from './machelSlice'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import { RootTabScreenProps } from '../../types'
import Overview from './overview'
import ReportsList from './overview/reportsList'
import ReportDetail from './reportDetail'
import AddReport from './addReport'
import Settings from './settings/index'
import EmptyScreen from '../../components/emptyScreen'
import { Alert, DeviceEventEmitter, EmitterSubscription } from 'react-native'

export const Stack = createNativeStackNavigator<MachelStackParamList>()

// Machel
function Machel(props: RootTabScreenProps<'Machel'>) {
  const dispatch = useAppDispatch()
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)

  const fetchDataListener = useRef<EmitterSubscription>()

  const fetchData = async () => {
    BackendRequest('machel/data')
      .then((data: MachelBackendData) => {
        if (data.success && data.statuses && data.editors) {
          dispatch(setStatuses(data.statuses))

          dispatch(
            setEditors(
              data.editors.map((el: Editor) => ({
                id: el.id,
                userId: Number(el.userId),
                firstName: el.firstName,
                lastName: el.lastName,
                defaultEditor: el.defaultEditor,
              }))
            )
          )
        } else {
          Alert.alert('Fehler: ' + (data.message ? data.message : ''))
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => console.log(error))
  }

  useEffect(() => {
    fetchData()
    fetchDataListener.current = DeviceEventEmitter.addListener(
      'mwapp.event.machel.settings.fetchData',
      fetchData
    )

    return () => {
      if (fetchDataListener.current) fetchDataListener.current.remove()
    }
  }, [])

  return (
    <SafeAreaView style={styles.container} edges={['top', 'left', 'right']}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {Layout.isMobile === false && (
          <View
            style={{
              flex: 1,
              marginRight: 25,
              // borderRight: '1px solid ' + Colors[colorScheme].text,
              paddingTop: 20,
            }}
          >
            <ReportsList />
          </View>
        )}
        <View style={{ flex: Layout.isMobile ? 1 : 2 }}>
          <Stack.Navigator
            initialRouteName={'ReportList'}
            screenOptions={{
              headerTintColor: Colors[colorScheme].tint,
              headerTitleStyle: { color: Colors[colorScheme].text },
            }}
          >
            <Stack.Screen
              name="ReportList"
              component={Layout.isMobile ? Overview : EmptyScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="ReportDetail"
              component={ReportDetail}
              options={() => {
                return Layout.isMobile
                  ? {
                      title: 'Meldung',
                      // route && route.params && route.params.timestamp
                      //   ? Moment(route.params.timestamp).format('DD.MM.YY HH:mm')
                      //   : 'Meldung',
                      headerBackTitle: 'Zurück',
                    }
                  : {
                      headerShown: false,
                    }
              }}
            />
            <Stack.Screen
              name="AddReport"
              component={AddReport}
              options={({ navigation }) => ({
                title: ' ',
                headerBackTitle: 'Zurück',
                headerRight: () => (
                  <Button
                    onPress={() => {
                      dispatch(reset({ startNewReport: false }))
                      navigation.navigate('ReportList')
                    }}
                    title="Abbrechen"
                  ></Button>
                ),
              })}
            />
            <Stack.Screen
              name="Settings"
              component={Settings}
              initialParams={{}}
              options={() => ({
                title: 'Einstellungen',
                // route && route.params && route.params.timestamp
                //   ? Moment(route.params.timestamp).format('DD.MM.YY HH:mm')
                //   : 'Meldung',
                headerBackTitle: 'Zurück',
              })}
            />
          </Stack.Navigator>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  bold: {
    fontWeight: 'bold',
  },

  rowBack: {
    alignItems: 'center',
    backgroundColor: 'red',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 85,
  },
  backRightBtnRight: {
    backgroundColor: 'red',
    left: 0,
  },
  backTextWhite: {
    color: '#FFF',
  },
})

export type MachelStackParamList = {
  ReportList:
    | { abortReport?: boolean; reload?: boolean; showReport?: number }
    | undefined
  ReportDetail: { report: number; reportDate?: string }
  AddReport: { reset: boolean } | undefined
  Settings: { onEditorUpdate?: () => void } | undefined
}

export type MachelStackScreenProps<Screen extends keyof MachelStackParamList> =
  NativeStackScreenProps<MachelStackParamList, Screen>

interface MachelBackendData extends ApiReturnValue {
  statuses?: {
    id: number
    description: string
    setByEditor: boolean
    symbol: FontAwesomeSymbol
    color: string
  }[]
  editors?: Editor[]
}

export default Machel
