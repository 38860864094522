import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import {
  StyleSheet,
  ActivityIndicator,
  ScrollView,
  Alert,
  View,
  Platform,
} from 'react-native'
import { Text } from '../../../components/Themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { Report, setReports, setReportsUpToDate, reset } from '../machelSlice'

import BackendRequest, {
  ApiReturnValue,
} from '../../../components/backendRequest'
import { ColorButton, ColorButtonRow } from '../../../components/Themed'
import ListItem from './item'

import { FontAwesome } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'

interface BackendReportsData extends ApiReturnValue {
  reports?: Report[]
}

// Fluchtwege: Kontrollrunden-Übersicht
function ReportsList() {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const { colorScheme, machel, user } = useAppSelector(
    ({ app, machel, user }) => ({
      colorScheme: app.colorScheme,
      machel,
      user,
    })
  )

  const [isLoading, setIsLoading] = useState(true)

  const fetchReports = async () => {
    try {
      BackendRequest('machel/reports', { offset: 0 })
        .then((data: BackendReportsData) => {
          // console.log('beReports', data)
          if (data.success && data.reports) {
            dispatch(
              setReports(
                data.reports.map((report) => ({
                  id: Number(report.id),
                  user: report.user,
                  timestamp: report.timestamp,
                  subject: report.subject,
                  text: report.text,
                  location: report.location,
                  statusChanges: report.statusChanges,
                  assets: report.assets,
                  editor: report.editor,
                }))
              )
            )
            setIsLoading(false)
          } else {
            Alert.alert('Konnte Meldungen nicht laden. Offline?')
            console.log(
              'machel: overview: load reports: API error',
              data.reports ? data.reports : ''
            )
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => console.log(error))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const abortNewReport = () => {
    dispatch(reset({ startNewReport: false }))
    navigation.navigate('ReportList', { abortReport: true })
  }

  const handleAbort = () => {
    Platform.OS === 'web'
      ? abortNewReport()
      : Alert.alert('Abbrechen', 'Neue Meldung wirklich verwerfen?', [
          {
            text: 'Ja',
            onPress: () => {
              dispatch(reset({ startNewReport: false }))
              navigation.navigate('ReportList', { abortReport: true })
            },
            style: 'cancel',
          },
          {
            text: 'Zurück',
            // onPress: abortRound,
          },
        ])
  }

  const handleListItemClick = (reportId: number) => {
    navigation.navigate('ReportDetail', {
      report: reportId,
    })
  }

  useEffect(() => {
    fetchReports()
    const stop = navigation.addListener('focus', () => fetchReports())
    return stop
  }, [navigation])

  useEffect(() => {
    if (!machel.reportsUpToDate) fetchReports()
  }, [machel.reportsUpToDate])

  return (
    <View
      style={{
        flex: 1,
        paddingTop: 20,
      }}
    >
      <ColorButtonRow>
        {machel.addingNewReport ? (
          <>
            <ColorButton key="abort" variant="abort" onPress={handleAbort}>
              Abbrechen
            </ColorButton>

            <ColorButton
              key="continue"
              variant="tint"
              style={{ marginTop: 1 }}
              onPress={() => navigation.navigate('AddReport')}
            >
              Fortsetzen
            </ColorButton>
          </>
        ) : (
          <>
            {user.admin ? (
              <ColorButton
                key="settings"
                variant="secondary"
                onPress={() => navigation.navigate('Settings')}
              >
                <FontAwesome name="gear" color="#fff" size={18} />
              </ColorButton>
            ) : null}
            <ColorButton
              variant="tint"
              key="newReport"
              onPress={() => navigation.navigate('AddReport', { reset: true })}
            >
              <FontAwesome
                name="plus"
                color="#fff"
                size={17}
                style={{ marginRight: 15, fontSize: 17 }}
              />
              {'  '}
              Neue Meldung
            </ColorButton>
          </>
        )}
      </ColorButtonRow>
      {machel.reports.length === 0 ? (
        <View style={{ paddingTop: 40 }}>
          <ActivityIndicator />
        </View>
      ) : (
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <ScrollView
            pagingEnabled={true}
            scrollEnabled={true}
            style={{ flex: 1, flexDirection: 'column' }}
          >
            {machel.reports.map((report) => (
              <ListItem
                key={report.id}
                report={report}
                adminView={user.admin}
                onClick={handleListItemClick}
              />
            ))}
          </ScrollView>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  bold: {
    fontWeight: 'bold',
  },

  rowBack: {
    alignItems: 'center',
    backgroundColor: 'red',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 85,
  },
  backRightBtnRight: {
    backgroundColor: 'red',
    left: 0,
  },
  backTextWhite: {
    color: '#FFF',
  },
})

export default ReportsList
