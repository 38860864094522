import React, { useState } from 'react'
import { View } from 'react-native'
import { Switch } from 'react-native'
import { Text, TextInput } from '../../components/Themed'
import { useAppSelector } from '../../redux/hooks'

interface Props {
  type: string
  name: string
  title: string
  info?: string
  inputType: string
  value: string | number | boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any
}

function SettingsItem({
  type,
  name,
  title,
  info,
  inputType,
  value,
  onChange,
}: Props): JSX.Element {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const [val, setVal] = useState(value)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: string | boolean) => {
    console.log(value)
    setVal(inputType === 'boolean' ? !val : value)
    onChange({
      type,
      name,
      value: inputType === 'boolean' ? !val : value,
    })
  }
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingVertical: 15,
        paddingHorizontal: 15,
        borderBottomWidth: 0.5,
        borderBottomColor: colorScheme === 'dark' ? '#444' : '#aaa',
      }}
    >
      <View style={{ flex: 1, justifyContent: 'center', height: 33 }}>
        <Text style={{ marginTop: info ? 15 : 0 }}>{title}</Text>
        {info && (
          <Text variant="textMuted" style={{ fontSize: 11, marginTop: 2 }}>
            {info}
          </Text>
        )}
      </View>
      <View
        style={{
          flex: 1,
          alignItems: 'flex-end',
          justifyContent: 'center',
          paddingRight: 10,
        }}
      >
        {inputType === 'boolean' ? (
          <Switch
            style={{ flex: 1 }}
            trackColor={{
              false: colorScheme === 'dark' ? '#777' : '#555',
              true: 'green',
            }}
            thumbColor="white"
            onValueChange={handleChange}
            value={typeof val === 'boolean' ? val : val === 1}
          />
        ) : inputType === 'number' ? (
          <TextInput
            style={{ flex: 1, minWidth: 5 }}
            keyboardType="number-pad"
            onChangeText={handleChange}
            value={typeof val === 'string' ? val : val.toString()}
          />
        ) : (
          <TextInput
            style={{ flex: 1 }}
            onChangeText={handleChange}
            value={typeof val === 'string' ? val : val.toString()}
          />
        )}
      </View>
    </View>
  )
}

export default SettingsItem
