import React, { useState, useEffect, useRef } from 'react'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { setColorScheme } from '../redux/appSlice'
import * as Network from 'expo-network'
import { StatusBar } from 'expo-status-bar'
import { Platform, Appearance, AppState } from 'react-native'
import { Text, View } from '../components/Themed'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import Navigation, { NavigateToProp } from '../navigation'
import AppUpdater from '../components/AppUpdater'
import Notifications from '../components/Notifications'

//TODO: Replace react-native-appearance by { useColorScheme } from 'react-native' define type in new App.types.d

function AppView(): JSX.Element {
  const dispatch = useAppDispatch()
  //TODO: dislpay all updates in updateIfnfoModal that individual customer missed
  const [isLoadingComplete, setLoadingComplete] = useState(true)
  // const colorScheme = useColorScheme()

  const [navigateTo, setNavigateTo] = useState<NavigateToProp>({
    target: null,
    routeParams: {},
  })

  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const lastAppState = useRef(AppState.currentState)
  const [isOnline, setIsOnline] = useState(true)

  const checkNetworkStatus = () => {
    Network.getNetworkStateAsync().then((state) => {
      if (!state.isInternetReachable) {
        if (isOnline) setIsOnline(false)
        setTimeout(checkNetworkStatus, 5000)
      } else if (!isOnline) setIsOnline(true)
    })
  }
  checkNetworkStatus()

  /*
  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      try {
        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
        })
        // TODO: Wird SpaceMono überhaupt benutzt oder kann das weg?
      } catch (e) {
        console.warn(e)
      } finally {
        // onLoaded()
      }
    }
    loadResourcesAndDataAsync()
  }, [])
  */

  const _handleAppStateChange = (
    nextAppState: typeof AppState.currentState
  ) => {
    if (
      lastAppState.current.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      const cs = Appearance.getColorScheme()
      dispatch(setColorScheme(cs ? cs : 'light'))
    }

    lastAppState.current = nextAppState
  }

  function onColorSchemeChange() {
    // dispatch(setColorScheme(preferences.colorScheme))
    const cs = Appearance.getColorScheme()
    if (cs) dispatch(setColorScheme(cs))
  }

  useEffect(() => {
    /*
    const redirectUrl = Linking.createURL('aktuell', {
      queryParams: { hello: 'world' },
    })
    console.log(redirectUrl)

    Linking.addEventListener('url', (e) =>
      console.log(Linking.parse(e.url).queryParams)
    )
*/
    AppState.addEventListener('change', _handleAppStateChange)
    Appearance.addChangeListener(onColorSchemeChange)
    const cs = Appearance.getColorScheme()
    dispatch(setColorScheme(cs ? cs : 'light'))

    return () => {
      AppState.removeEventListener('change', _handleAppStateChange)
      Appearance.removeChangeListener(onColorSchemeChange)
    }
  }, [])

  return (
    <SafeAreaProvider>
      <StatusBar
        style={
          Platform.OS === 'ios'
            ? colorScheme === 'dark'
              ? 'light'
              : 'dark'
            : 'auto'
        }
      />

      <Notifications onNavigateTo={(target) => setNavigateTo(target)} />
      <AppUpdater />
      {!isLoadingComplete ? (
        <View></View>
      ) : (
        <>
          {!isOnline && (
            <View
              style={{
                backgroundColor: '#ff0000',
                paddingBottom: 6,
                paddingTop: 35,
              }}
            >
              <Text
                style={{
                  color: '#ffffff',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Du bist offline, Daten werden nicht gespeichert!
              </Text>
            </View>
          )}

          {/* <View
            style={
              Platform.OS === 'web'
                ? { flex: 1, flexDirection: 'column', alignItems: 'center' }
                : {}
            }
          >
            <View
              style={
                Platform.OS === 'web'
                  ? {
                      flexDirection: 'colum',
                      alignItems: 'stretch',
                      width: '100%',
                      maxWidth: 6000,
                      height: '100vh',
                    }
                  : {}
              }
            > */}
          <Navigation navigateTo={navigateTo} />
          {/*
            </View>
          </View>
              */}
        </>
      )}
    </SafeAreaProvider>
  )
}

export default AppView
