import { FontAwesome } from '@expo/vector-icons'
import React, { useState } from 'react'
import BackendRequest, { ApiReturnValue } from '../../components/backendRequest'
import { AccountStackScreenProps } from './index'
import { View, StyleSheet, SectionList, Pressable } from 'react-native'
import { Text } from '../../components/Themed'
import { User } from '../../types/user'
import { useAppSelector } from '../../redux/hooks'

type ItemProps = {
  user: User
} & AccountStackScreenProps<'userManagement'>

interface BackendRequestDataUsers extends ApiReturnValue {
  users?: User[]
}

// Admin-Interface, das alle App-Nutzer mit jeweiligem Status anzeigt
function UserListItem({ user, navigation }: ItemProps) {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  return (
    <Pressable
      key={user.id}
      onPress={() => navigation.navigate('userEdit', { user, method: 'edit' })}
      style={{
        flex: 1,
        width: '100%',
        paddingVertical: 14,
        paddingHorizontal: 5,
        marginVertical: 0,
        flexDirection: 'row',
        borderBottomWidth: 0.5,
        backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
        borderBottomColor: colorScheme === 'dark' ? '#444' : '#ccc',
      }}
    >
      <View style={{ flex: 3, justifyContent: 'center', height: 33 }}>
        {user.deactivated ? (
          <Text style={{ paddingBottom: 0, color: '#777' }}>
            {user.firstName} {user.lastName}
          </Text>
        ) : (
          <>
            <Text style={{ paddingBottom: 0 }}>
              {user.firstName} {user.lastName}
            </Text>
            <Text style={{ paddingTop: 6, paddingLeft: 6 }}>
              {user.onDuty ? (
                <Text style={{ color: 'green' }}>im Dienst</Text>
              ) : (
                <Text style={{ color: '#777' }}>schläft</Text>
              )}
            </Text>
          </>
        )}
      </View>
      <View
        style={{
          flex: 1,
          alignItems: 'flex-end',
          justifyContent: 'center',
          paddingRight: 10,
        }}
      >
        <Text>
          <FontAwesome
            size={15}
            name="chevron-right"
            style={{ color: '#777' }}
          />
        </Text>
      </View>
    </Pressable>
  )
}

type UserListSegment = {
  title: string
  data: User[]
}

type Props = AccountStackScreenProps<'userManagement'>
export default function UserManagementScreen({
  navigation,
  route,
}: Props): JSX.Element {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const [users, setUsers] = useState<UserListSegment[]>([])

  const getUsers = () => {
    BackendRequest('getUsers')
      .then((data: BackendRequestDataUsers) => {
        //TODO: add klicks unfinished to session
        if (data.success && data.users) {
          setUsers([
            {
              title: 'Durchführer',
              data: data.users.filter(
                (user: User) => user.role === 'durchfuehrer'
              ),
            },
            {
              title: 'Admins',
              data: data.users.filter((user: User) => user.role === 'admin'),
            },
          ])
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => console.log(error))
  }

  React.useEffect(() => {
    getUsers()
    const stop = navigation.addListener('focus', () => getUsers())
    return stop
  }, [])
  return (
    <View
      style={{
        ...styles.container,
      }}
    >
      <SectionList
        style={{ flex: 1 }}
        sections={users}
        keyExtractor={(item, index) => 'ggg' + item.id + index}
        renderItem={({ item }) => (
          <UserListItem
            key={item.id}
            user={item}
            navigation={navigation}
            route={route}
          />
        )}
        renderSectionHeader={({ section: { title } }) => (
          <Text
            style={{
              ...styles.header,
              backgroundColor: colorScheme === 'dark' ? '#111' : '#eee',
              borderBottomColor: colorScheme === 'dark' ? '#666' : '#666',
              borderTopColor: colorScheme === 'dark' ? '#999' : '#999',
            }}
          >
            {title}
          </Text>
        )}
      />

      {/* Use a light status bar on iOS to account for the black space above the modal */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    alignContent: 'stretch',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
    paddingBottom: 10,
    paddingTop: 11,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
