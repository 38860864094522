import React from 'react'
import { AppState as AppStateType } from '../../redux/appSlice'
import { StyleSheet, View } from 'react-native'
import { DefaultTheme, DarkTheme } from '@react-navigation/native'
import { Title } from '../../components/Themed'
import { FontAwesome } from '@expo/vector-icons'
import Colors from '../../constants/Colors'
import { useAppSelector } from '../../redux/hooks'

interface Props {
  icon?: keyof typeof FontAwesome
  title: string
}

export const SettingsListSectionHeader = (props: Props): JSX.Element => {
  const { icon, title } = props
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)

  return (
    <View
      style={{
        ...styles.styledSectionWrapper,
        backgroundColor:
          colorScheme === 'dark'
            ? DarkTheme.colors.background
            : DefaultTheme.colors.background,
      }}
    >
      {icon && (
        <View style={{ padding: 8, borderRadius: 8 }}>
          <FontAwesome
            size={23}
            name={icon}
            style={{ color: Colors[colorScheme].tint }}
          />
        </View>
      )}

      <Title style={{ marginLeft: 12 }}>{title}</Title>
    </View>
  )
}

const styles = StyleSheet.create({
  styledSectionWrapper: {
    marginTop: 32,
    marginBottom: 24,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
})
