import React from 'react'
import { useAppSelector } from '../../../redux/hooks'
import Colors from '../../../constants/Colors'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import { MachelStackScreenProps } from '../index'
import Macher from './macher'

export type MachelSettingsStackParamList = {
  Macher: undefined
}

export type MachelSettingsStackScreenProps<
  Screen extends keyof MachelSettingsStackParamList
> = NativeStackScreenProps<MachelSettingsStackParamList, Screen>

const Stack = createNativeStackNavigator<MachelSettingsStackParamList>()

type Props = MachelStackScreenProps<'Settings'>

// Machel
function MachelSettings() {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)

  return (
    <Stack.Navigator
      initialRouteName={'Macher'}
      screenOptions={{
        headerTintColor: Colors[colorScheme].tint,
        headerTitleStyle: { color: Colors[colorScheme].text },
      }}
    >
      <Stack.Screen
        name="Macher"
        component={Macher}
        options={() => ({
          headerShown: false,
          title: 'Macher',
          headerBackTitle: 'Zurück',
        })}
      />
    </Stack.Navigator>
  )
}

export default MachelSettings
