/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import {
  getPathFromState,
  getStateFromPath,
  LinkingOptions,
} from '@react-navigation/native'
import * as Linking from 'expo-linking'

import { RootStackParamList } from '../types'

const pageNotFoundPath = '/NotFound'

export const getLinkingPathOptions = (): Pick<
  LinkingOptions,
  'getStateFromPath' | 'getPathFromState'
> => {
  let currentUrl = ''

  return {
    getStateFromPath: (path, config) => {
      currentUrl = path

      return getStateFromPath(currentUrl, config)
    },
    getPathFromState: (state, config) => {
      const path = getPathFromState(state, config)

      return path === pageNotFoundPath ? currentUrl : path
    },
  }
}

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Aktuell: {
            screens: {
              Aktuell: 'aktuell',
            },
            path: 'aktuell',
          },
          EmergencyExits: {
            screens: {
              Overview: 'overview',
              RoundDetail: 'roundDetail',
              Check: 'check',
              CheckScan: 'checkScan',
              CheckDetail: 'checkDetail',
              CheckList: 'checkCheckpointList',
            },
            path: 'emergexits',
          },
          Machel: {
            screens: {
              ReportList: 'reports',
              ReportDetail: 'report',
              AddReport: 'addreport',
              AddReportPhoto: 'addreportphoto',
              Settings: {
                screens: {
                  Macher: 'macher',
                },
                path: 'settings',
              },
            },
            path: 'machel',
          },
          Schnack: {
            screens: {
              Schnack: '/',
            },
            path: 'schnack',
          },
          account: {
            screens: {
              user: '/',
              userManagement: 'userManagement',
              userEdit: 'userEdit',
            },
            path: 'user',
          },
        },
      },
      Login: {
        screens: {
          Login: 'login',
        },
      },
      PasswordForgotten: {
        screens: {
          PasswordForgotten: 'passwordforgotten',
        },
      },
      ResetPassword: {
        screens: {
          ResetPassword: 'resetpassword',
        },
      },
      Modal: 'modal',

      NotFound: '*',
    },
  },
  ...getLinkingPathOptions(),
}

// Use your 404 Screen name instead of PageNotFound
export default linking
