import React from 'react'
import { Button } from 'react-native'
import Colors from '../../constants/Colors'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import { RootTabScreenProps } from '../../types'
import User from './user'
import UserSettings from './userSettings'
import UserManagement from './userManagement'
import UserEdit from './userEdit'
import DfAppSettings from '../dfAppSettings/dfAppSettings'
import SettingsTest from '../settingsList/settingsList'
import { useAppSelector } from '../../redux/hooks'

export type AccountStackParamList = {
  user: undefined
  userManagement: undefined
  userEdit: {
    method: string
    user?: { id: number; firstName: string; lastName: string }
  }
  userSettings: undefined
  dfAppSettings: undefined
  settingsTest: undefined
}

const Stack = createNativeStackNavigator<AccountStackParamList>()

export type AccountStackScreenProps<
  Screen extends keyof AccountStackParamList
> = NativeStackScreenProps<AccountStackParamList, Screen>

type Props = RootTabScreenProps<'Account'>

// Fluchtwegs-Kontrolle
function Account({ navigation }: Props): JSX.Element {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)

  return (
    <Stack.Navigator
      initialRouteName="user"
      screenOptions={{
        // headerTintColor: Colors[colorScheme].tint,
        headerTitleStyle: { color: Colors[colorScheme].text },
      }}
    >
      <>
        <Stack.Screen
          name="user"
          component={User}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="userManagement"
          component={UserManagement}
          options={{
            title: 'Benutzer',
            headerBackTitle: 'Zurück',
            headerRight: () => (
              <Button
                color={Colors[colorScheme].tint}
                onPress={() =>
                  navigation.navigate(
                    'userEdit' as never,
                    { method: 'add' } as never
                  )
                }
                title="Hinzufügen"
              />
            ),
          }}
        />
        <Stack.Screen
          name="userEdit"
          component={UserEdit}
          options={({ route }) => ({
            title:
              route && route.params && route.params.method
                ? route.params.method === 'add'
                  ? 'Neuer Benutzer'
                  : route.params.user
                  ? route.params.user.firstName + ' bearbeiten'
                  : 'Bearbeiten'
                : 'Bearbeiten',
            headerBackTitle: 'Benutzer',
          })}
        />
        <Stack.Screen
          name="userSettings"
          component={UserSettings}
          options={{
            title: 'Einstellungen',
            headerBackTitle: 'Zurück',
          }}
        />
        <Stack.Screen
          name="dfAppSettings"
          component={DfAppSettings}
          options={{ title: 'Df-Einstellungen', headerBackTitle: 'Zurück' }}
        />
        <Stack.Screen
          name="settingsTest"
          component={SettingsTest}
          options={{
            title: 'Df-Einstellungen Test',
            headerBackTitle: 'Zurück',
          }}
        />
      </>
    </Stack.Navigator>
  )
}

export default Account
