import React from 'react'

import { StatusChange as StatusChangeType, ReportMedia } from '../machelSlice'
import { Dimensions, View } from 'react-native'
import { Text } from '../../../components/Themed'
import { FontAwesome } from '@expo/vector-icons'
import { useAppSelector } from '../../../redux/hooks'
import FormatDate from '../../../components/formatDate'
import Assets from './media'

// Machel - Meldungsdetail-Ansicht - Bearbeitungsfenster für zuständigen Macher

function StatusChange({
  status,
  timestamp,
  user,
  comment,
  statusAssets,
  firstItem,
}: StatusChangeType & {
  firstItem: boolean
  statusAssets: ReportMedia[]
}): JSX.Element {
  const { colorScheme, reportStatuses } = useAppSelector(({ app, machel }) => ({
    colorScheme: app.colorScheme,
    reportStatuses: machel.statuses,
  }))

  const iconRowWidth = 31

  return (
    <View
      style={{
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 9,
        paddingRight: 13,
        marginVertical: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderBottomWidth: 0.5,
        borderTopWidth: firstItem ? 0.5 : 0,
        borderTopColor: colorScheme === 'dark' ? '#333' : '#bbb',
        backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
        borderBottomColor: colorScheme === 'dark' ? '#333' : '#bbb',
      }}
    >
      <Text
        style={{ textAlign: 'center', paddingRight: 13, width: iconRowWidth }}
      >
        {reportStatuses.length > 0 && (
          <FontAwesome
            size={23}
            name={reportStatuses.filter((el) => el.id === status)[0].symbol}
            style={{
              color: reportStatuses.filter((el) => el.id === status)[0].color,
            }}
          />
        )}
      </Text>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            marginTop: 4,
          }}
        >
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ flex: 1, textAlign: 'left', paddingHorizontal: 3 }}>
              <Text
                style={{ flex: 1, paddingHorizontal: 3 }}
                variant="textLightMuted"
              >
                {user.firstName} {user.lastName}:
              </Text>{' '}
              {reportStatuses.filter((el) => el.id === status)[0].description}
            </Text>
          </View>

          <View style={{ paddingHorizontal: 3 }}>
            <Text variant="textLightMuted" style={{ fontSize: 13 }}>
              {FormatDate(timestamp)}
            </Text>
          </View>
        </View>
        {comment ? (
          <Text
            variant="textLightMuted"
            style={{
              marginTop: 20,
              paddingLeft: 4,
              paddingRight: 5,
              lineHeight: 21,
            }}
          >
            {comment}
          </Text>
        ) : null}
        {statusAssets.length > 0 && (
          <View style={{}}>
            {/* }<Text style={styles.title}>Medien</Text> */}
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <Assets
                assets={statusAssets}
                thumbsAlign="left"
                maxThumbnailsPerRow={3}
                containerWidth={
                  Dimensions.get('window').width - iconRowWidth - 30
                }
              />
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

export default StatusChange
