import { FontAwesome } from '@expo/vector-icons'
import React, { useState } from 'react'
import { View, Dimensions, Pressable, Image } from 'react-native'
import ImageView from './mediaImageView'
import { ReportMedia as ReportMediaType } from '../machelSlice'
import Layout from '../../../constants/Layout'

const ReportMedia = ({
  assets,
  showAddButton = false,
  onAdd,
  style,
  maxThumbnailsPerRow = 3,
  thumbsAlign = 'center',
  containerWidth = Dimensions.get('window').width,
}: Props) => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const THUMBNAILS_PER_ROW = Math.max(
    Layout.isMobile ? 2 : 4,
    assets.length > 0
      ? assets.length < maxThumbnailsPerRow
        ? assets.length
        : maxThumbnailsPerRow
      : maxThumbnailsPerRow
  )

  const THUMBNAIL_SPACING = 9
  const THUMBNAIL_SIZE =
    (containerWidth - 3 - THUMBNAIL_SPACING * (THUMBNAILS_PER_ROW * 2 + 2)) /
    THUMBNAILS_PER_ROW

  const handleIconClick = (index: number) => {
    setCurrentImage(index)
    if (!showLightbox) setShowLightbox(true)
  }

  const handleAddItemButtonPress = () => {
    if (onAdd) onAdd()
    else {
      console.log('machel: add item button: no function defined')
    }
  }

  return (
    <View style={{ flex: 1, ...style, flexDirection: 'column' }}>
      <ImageGrid
        assets={assets}
        showAddButton={showAddButton}
        onAddItemClick={handleAddItemButtonPress}
        onItemClick={handleIconClick}
        thumbnailSpacing={THUMBNAIL_SPACING}
        thumbnailSize={THUMBNAIL_SIZE}
        align={thumbsAlign}
      />
      <ImageView
        images={assets.map((el) => ({ uri: el.uri }))}
        // keyExtractor={(imageSrc, index) => index.toString()}
        // animationType="none"
        imageIndex={currentImage}
        visible={showLightbox}
        onRequestClose={() => setShowLightbox(false)}
        presentationStyle="pageSheet"
      />
    </View>
  )
}

type Props = {
  assets: Omit<ReportMediaType, 'filename'>[]
  maxThumbnailsPerRow?: number
  showAddButton?: boolean
  onAdd?: () => void
  style?: Record<string, unknown>
  thumbsAlign?: 'left' | 'center'
  containerWidth?: number
}

export const ImageGrid = ({
  assets,
  showAddButton,
  onItemClick,
  onAddItemClick,
  thumbnailSize,
  thumbnailSpacing,
  align,
}: {
  assets: Omit<ReportMediaType, 'filename'>[]
  showAddButton?: boolean
  onItemClick?: (imageIndex: number) => void
  onAddItemClick?: () => void
  thumbnailSize: number
  thumbnailSpacing: number
  align: 'left' | 'center'
}) => (
  <View
    style={{
      margin: thumbnailSpacing,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      // justifyContent: 'flex-start',
      justifyContent:
        showAddButton || align === 'left' ? 'flex-start' : 'center',
    }}
  >
    {assets.map((item, index) => (
      <ListItem
        key={item.id}
        asset={item}
        index={index}
        width={thumbnailSize}
        height={thumbnailSize}
        margin={thumbnailSpacing}
        onClick={() => {
          if (onItemClick) onItemClick(index)
        }}
      />
    ))}
    {showAddButton ? (
      <AddItemButton
        key="add"
        width={thumbnailSize}
        height={thumbnailSize}
        margin={thumbnailSpacing}
        onPress={
          onAddItemClick
            ? onAddItemClick
            : () => {
                return
              }
        }
      />
    ) : null}
  </View>
)

const AddItemButton = ({
  onPress,
  margin,
  width,
  height,
}: {
  onPress: () => void
  margin: number
  width: number
  height: number
}) => (
  <Pressable onPress={onPress}>
    <View
      style={{
        margin,
        width,
        height,
        backgroundColor: 'rgb(120, 120, 120)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FontAwesome name="plus" color="#fff" size={27} />
    </View>
  </Pressable>
)

const ListItem = ({
  asset,
  index,
  onClick,
  margin,
  width,
  height,
}: {
  asset: Omit<ReportMediaType, 'filename'>
  index: number
  onClick: (image: number) => void
  margin: number
  width: number
  height: number
}) => (
  <Pressable onPress={() => onClick(index)}>
    <Image
      source={{ uri: asset.uri }}
      style={{
        margin,
        width,
        height,
      }}
    />
  </Pressable>
)

export default ReportMedia
