import React, { useState } from 'react'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import Colors from '../../constants/Colors'
import List from './settingsList'
import ItemDetail from './itemDetail'

import { SettingsItem as SettingsItemType } from './settingsListItem'
import { SettingsSection as SectionType } from './settingsList'
import SettingsItem from '../account/settingsItem'
import { useAppSelector } from '../../redux/hooks'

export type SettingsItem = SettingsItemType
export type SettingsData = SectionType[]
export type SettingsListStackParamList = {
  List: {
    data: SettingsData
  }
  ItemDetail: {
    item: SettingsItem
  }
}

const Stack = createNativeStackNavigator<SettingsListStackParamList>()

export type SettingsListStackScreenProps<
  Screen extends keyof SettingsListStackParamList
> = NativeStackScreenProps<SettingsListStackParamList, Screen>

type Props = {
  data: SettingsData
}

function SettingsList({ data }: Props): JSX.Element {
  const colorScheme = useAppSelector(({ app }) => app.colorScheme)
  const [stateData, setStateData] = useState(data)

  return (
    <Stack.Navigator
      initialRouteName="List"
      screenOptions={{
        // headerTintColor: Colors[colorScheme].tint,
        headerTitleStyle: { color: Colors[colorScheme].text },
      }}
    >
      <>
        <Stack.Screen
          name="List"
          component={List}
          initialParams={{ data }}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="ItemDetail"
          component={ItemDetail}
          options={({ route }) => ({
            headerShown: false,
            // title: route.params.item.title,
            // headerBackTitle: 'Zurück',
          })}
        />
      </>
    </Stack.Navigator>
  )
}

export default SettingsList
