import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import {
  StyleSheet,
  ActivityIndicator,
  ScrollView,
  Alert,
  View,
} from 'react-native'
import { Text } from '../../../components/Themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import ReportsList from './reportsList'
import { Report, setReports, reset } from '../machelSlice'
import { Stack } from '..'
import BackendRequest, {
  ApiReturnValue,
} from '../../../components/backendRequest'
import { ColorButton, ColorButtonRow } from '../../../components/Themed'
import ListItem from './item'
import { MachelStackScreenProps } from '../index'
import { FontAwesome } from '@expo/vector-icons'
import EmptyScreen from '../../../components/emptyScreen'

interface BackendReportsData extends ApiReturnValue {
  reports?: Report[]
}

// Fluchtwege: Kontrollrunden-Übersicht
function Overview({ navigation, route }: MachelStackScreenProps<'ReportList'>) {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const handleAbort = () =>
    Alert.alert('Abbrechen', 'Neue Meldung wirklich verwerfen?', [
      {
        text: 'Ja',
        onPress: () => dispatch(reset({ startNewReport: false })),
        style: 'cancel',
      },
      {
        text: 'Zurück',
        // onPress: abortRound,
      },
    ])

  const fetchReports = async () => {
    try {
      BackendRequest('machel/reports', { offset: 0 })
        .then((data: BackendReportsData) => {
          // console.log('beReports', data)
          if (data.success && data.reports) {
            dispatch(
              setReports(
                data.reports.map((report) => ({
                  id: Number(report.id),
                  user: report.user,
                  timestamp: report.timestamp,
                  subject: report.subject,
                  text: report.text,
                  location: report.location,
                  statusChanges: report.statusChanges,
                  assets: report.assets,
                  editor: report.editor,
                }))
              )
            )
            setIsLoading(false)
          } else {
            Alert.alert('Konnte Meldungen nicht laden. Offline?')
            console.log(
              'machel: overview: load reports: API error',
              data.reports ? data.reports : ''
            )
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => console.log(error))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    if (route && route.params) {
      if (route.params.abortReport) dispatch(reset({ startNewReport: false }))
      if (route.params.reload) fetchReports()

      if (
        route.params.showReport &&
        typeof route.params.showReport === 'number'
      ) {
        navigation.navigate('ReportDetail', { report: route.params.showReport })
      }
    }
  }, [route.params])

  return (
    <SafeAreaView style={styles.container} edges={['top', 'left', 'right']}>
      {Layout.isMobile ? <ReportsList /> : <EmptyScreen />}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  bold: {
    fontWeight: 'bold',
  },

  rowBack: {
    alignItems: 'center',
    backgroundColor: 'red',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 85,
  },
  backRightBtnRight: {
    backgroundColor: 'red',
    left: 0,
  },
  backTextWhite: {
    color: '#FFF',
  },
})

export default Overview
