import React from 'react'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import * as SecureStore from '../../components/secureLocalStorage'
import { signOut, setOnDuty } from '../../redux/userSlice'
import { StyleSheet, Pressable, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AccountStackScreenProps } from './index'
import { Text } from '../../components/Themed'
import { FontAwesome } from '@expo/vector-icons'
import BackendRequest from '../../components/backendRequest'

// Durchführer-View in Bottom-Tab-Navigator
function User({ navigation }: AccountStackScreenProps<'user'>) {
  const dispatch = useAppDispatch()
  const { user, appUpdateId, showUpdateText, colorScheme } = useAppSelector(
    ({ user, app }) => ({
      user,
      appUpdateId: app.appUpdateId,
      showUpdateText: app.showUpdateText,
      colorScheme: app.colorScheme,
    })
  )

  const handleSignOut = () => {
    SecureStore.deleteItemAsync('user_token')
    SecureStore.deleteItemAsync('user_id')
    SecureStore.deleteItemAsync('api_key')

    dispatch(signOut())
  }

  const handleDutyChange = () => {
    const stat = !user.onDuty

    dispatch(setOnDuty(!user.onDuty))
    BackendRequest('userSetOnDuty', {
      onDuty: stat ? 1 : 0,
    })
      .then((data: { success: boolean; message?: string }) => {
        //TODO: add klicks unfinished to session
        if (!data.success) {
          console.log('apiError: ', data.message)
          return false
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => console.log(error))
  }

  const sendPush = () => {
    BackendRequest('sendPush', {
      userName: user.firstName,
    })
      .then((data: { success: boolean; message?: string }) => {
        console.log(data)
        if (!data.success) {
          console.log('apiError: ', data.message)
          return false
        } else {
          return
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => console.log(error))
  }

  return (
    <SafeAreaView style={styles.container}>
      <Text
        style={{
          ...styles.title,
          fontWeight: 'normal',
          fontSize: 22,
          marginBottom: 9,
        }}
      >
        {user?.firstName} {user?.lastName}
      </Text>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 14,
          marginTop: 0,
          fontWeight: 'bold',
          letterSpacing: user.admin ? 3.5 : 2,
          opacity: 0.35,
          fontSize: 14,
        }}
      >
        {user.role.replace('ue', 'ü').toUpperCase()}
      </Text>
      <View style={{ marginBottom: 16, marginTop: 40, flexDirection: 'row' }}>
        <View style={{ flex: 2 }}></View>
        <Pressable
          style={{ flex: 3, justifyContent: 'center', alignItems: 'center' }}
          onPress={handleDutyChange}
        >
          <View
            style={{
              backgroundColor: user.onDuty ? 'red' : 'green',
              borderRadius: 40,
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: 18,
              paddingHorizontal: 18,
              flexWrap: 'nowrap',
              width: 200,
            }}
          >
            {user.onDuty ? (
              <Text style={{ color: '#fff', fontSize: 20, flexWrap: 'nowrap' }}>
                Feierabend&nbsp;&nbsp;
                <FontAwesome
                  size={21}
                  name="rocket"
                  style={{ color: '#fff' }}
                />
              </Text>
            ) : (
              <Text
                style={{
                  color: '#fff',
                  fontSize: 20,
                  flexWrap: 'nowrap',
                }}
              >
                <FontAwesome
                  size={18}
                  name="shield"
                  style={{ color: '#fff' }}
                />
                &nbsp;&nbsp;Dienstbeginn
              </Text>
            )}
          </View>
        </Pressable>

        <View style={{ flex: 2 }}></View>
      </View>
      <View style={{ marginBottom: 17, paddingHorizontal: 45 }}>
        <Text style={{ fontSize: 10, color: '#777777', textAlign: 'center' }}>
          Du bekommst Push-Nachrichten zu wichtigen Ereignissen nur während Du
          hier als im Dienst gemeldet bist.
        </Text>
      </View>
      <View style={{ marginBottom: 27, marginTop: 45, flexDirection: 'row' }}>
        <View style={{ flex: 4 }}></View>
        <Pressable style={{ flex: 3 }} onPress={handleSignOut}>
          <View
            style={{
              backgroundColor: '#777',
              borderRadius: 40,
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: 8,
              paddingHorizontal: 1,
            }}
          >
            <Text style={{ color: '#fff', fontSize: 14 }}>Ausloggen</Text>
          </View>
        </Pressable>
        <View style={{ flex: 4 }}></View>
      </View>

      <View
        style={{
          width: '100%',
          marginTop: 60,
          flexDirection: 'column',
          borderTopWidth: 0.5,
          borderTopColor: colorScheme === 'dark' ? '#444' : '#aaa',
        }}
      >
        {user.admin && (
          <Pressable
            onPress={() => navigation.navigate('userManagement')}
            style={{
              flexDirection: 'row',
              paddingLeft: 15,
              paddingVertical: 15,
              borderBottomWidth: 0.5,
              borderBottomColor: colorScheme === 'dark' ? '#444' : '#aaa',
            }}
          >
            <View style={{ flex: 5 }}>
              <Text>App-Nutzer verwalten</Text>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'center',
                paddingRight: 10,
              }}
            >
              <Text>
                <FontAwesome
                  size={15}
                  name="chevron-right"
                  style={{ color: '#777' }}
                />
              </Text>
            </View>
          </Pressable>
        )}
        <Pressable
          onPress={() => navigation.navigate('userSettings')}
          style={{
            flexDirection: 'row',
            paddingLeft: 15,
            paddingVertical: 15,
            borderBottomWidth: 0.5,
            borderBottomColor: colorScheme === 'dark' ? '#444' : '#aaa',
          }}
        >
          <View style={{ flex: 5 }}>
            <Text>Einstellungen</Text>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'center',
              paddingRight: 10,
            }}
          >
            <Text>
              <FontAwesome
                size={15}
                name="chevron-right"
                style={{ color: '#777' }}
              />
            </Text>
          </View>
        </Pressable>
        {user.id === 1 ? (
          <Pressable onPress={sendPush}>
            <Text>Push</Text>
          </Pressable>
        ) : null}
        {/*  */}
        <Text variant="textMuted" style={{ fontSize: 10, textAlign: 'center' }}>
          {appUpdateId}
          {' - '}
          {showUpdateText ? '1' : '0'}
        </Text>
      </View>

      {/* Use a light status bar on iOS to account for the black space above the modal */}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})

export default User
